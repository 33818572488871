import React from 'react';

import _chunk from 'lodash/chunk';
import { useForm } from 'react-final-form';

import { CheckboxWithField } from 'components/Checkbox';
import FormRow from 'components/FormRow';
import { useFormValues } from 'store/hooks/useFormValues';

interface ISingleSelectableCheckboxFieldProps {
  options: {
    name: string;
    caption: string;
    tooltip?: string;
  }[];
}

const SingleSelectableCheckboxField = ({ options }: ISingleSelectableCheckboxFieldProps) => {
  const { change } = useForm();
  const formValues = useFormValues();

  const uncheckOthers = (checkboxField: string) => {
    const checkboxFields = options.map(({ name }) => name);

    checkboxFields.forEach((field) => {
      if (field !== checkboxField && formValues[field]) {
        change(field, false);
      }
    });
  };

  return (
    <>
      {_chunk(options, 2).map((pair) => (
        <FormRow>
          {pair.map((option) => (
            <CheckboxWithField
              name={option.name}
              text={<span>{option.caption}</span>}
              tooltip={option.tooltip ?? undefined}
              onChange={() => uncheckOthers(option.name)}
              data-testid={`Checkbox-Field-${option.name}`}
            />
          ))}
        </FormRow>
      ))}
    </>
  );
};

export default SingleSelectableCheckboxField;
