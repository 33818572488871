import React from 'react';

import { Form } from 'react-final-form';

import FormRow from 'components/FormRow';
import { InputWithField } from 'components/Input';
import StaticField from 'components/StaticField';
import { TextAreaWithField } from 'components/TextArea';
import { usePartnerConfig } from 'config/partner/hooks';
import { requestCallback } from 'modules/ContactSidebar/CallbackRequestModal/service';
import { useFieldValidators } from 'shared/hooks/useFieldValidators';
import { useToasts } from 'shared/hooks/useToasts';
import { store } from 'store';
import useDispatchApiCall from 'utils/hooks/useDispatchApiCallHook';
import { useTranslations } from 'utils/hooks/useTranslations';
import { isLoggedInUser } from 'utils/user/conditionals';
import { getCurrentUser } from 'utils/user/getters';
import { combineValidators } from 'utils/validators';

const FORM_FIELDS = {
  firstName: 'firstName',
  lastName: 'lastName',
  phoneNumber: 'phoneNumber',
  zipCode: 'zipCode',
  message: 'message',
};

type Fields =
  | typeof FORM_FIELDS.firstName
  | typeof FORM_FIELDS.lastName
  | typeof FORM_FIELDS.phoneNumber
  | typeof FORM_FIELDS.zipCode
  | typeof FORM_FIELDS.message;

interface Props {
  onSubmitted: (values: Record<Fields, string>) => void;
}

const CallbackForm = ({ onSubmitted }: Props) => {
  const t = useTranslations('components.contactSidebar.callbackRequest');
  const userData = getCurrentUser(store.getState());
  const {
    details: { id: partnerName },
  } = usePartnerConfig();
  const isLoggedIn = isLoggedInUser();
  const { required, isPostalCode, minLength, maxLength, isPhoneNumber, hasPhonePrefix } =
    useFieldValidators();

  const { success } = useToasts();
  const { makeCall } = useDispatchApiCall({
    errorMessage: t('error'),
    isPendingInitially: false,
    showErrorNotification: false,
  });
  const handleCallbackRequested = async (values: Record<Fields, string>) => {
    await makeCall(
      requestCallback({
        partnerName,
        zipCode: userData?.zipCode || values[FORM_FIELDS.zipCode],
        message: values[FORM_FIELDS.message],
        firstName: userData?.firstName || values[FORM_FIELDS.firstName],
        lastName: userData?.lastName || values[FORM_FIELDS.lastName],
        phoneNumber: userData?.phoneNumber || values[FORM_FIELDS.phoneNumber],
      }),
      () => {
        success({ description: t('success') });
        onSubmitted(values);
      },
    );
  };

  return (
    <Form
      onSubmit={handleCallbackRequested}
      render={({ handleSubmit }) => (
        <form id="form-callback" onSubmit={handleSubmit}>
          <FormRow>
            {!isLoggedIn
              ? [
                  <InputWithField
                    caption={t('fields.firstName.caption')}
                    name={FORM_FIELDS.firstName}
                    validate={combineValidators(required, maxLength(100))}
                  />,
                  <InputWithField
                    caption={t('fields.lastName.caption', { roleSpecific: true })}
                    name={FORM_FIELDS.lastName}
                    validate={combineValidators(required, maxLength(100))}
                  />,
                  <InputWithField
                    caption={t('fields.phoneNumber.caption')}
                    name={FORM_FIELDS.phoneNumber}
                    validate={combineValidators(required, isPhoneNumber, hasPhonePrefix)}
                  />,
                ]
              : [
                  <StaticField
                    caption={t('fields.name.caption')}
                    text={`${userData?.firstName} ${userData?.lastName}`}
                  />,
                  !!userData?.phoneNumber && (
                    <StaticField
                      caption={t('fields.phoneNumber.caption')}
                      text={userData.phoneNumber}
                    />
                  ),
                ]}
          </FormRow>
          {!userData?.phoneNumber && (
            <FormRow>
              <InputWithField
                caption={t('fields.phoneNumber.caption')}
                name={FORM_FIELDS.phoneNumber}
                validate={combineValidators(required, isPhoneNumber, hasPhonePrefix)}
              />
            </FormRow>
          )}
          <FormRow>
            {userData?.zipCode ? (
              <StaticField caption={t('fields.postalCode.caption')} text={`${userData.zipCode}`} />
            ) : (
              <InputWithField
                caption={t('fields.postalCode.caption')}
                name={FORM_FIELDS.zipCode}
                validate={combineValidators(required, isPostalCode, minLength(5))}
              />
            )}
          </FormRow>
          <FormRow>
            <TextAreaWithField
              caption={t('fields.message.caption')}
              name={FORM_FIELDS.message}
              validate={combineValidators(required, maxLength(500))}
            />
          </FormRow>
        </form>
      )}
    />
  );
};

export default CallbackForm;
