import React, { useContext, useEffect } from 'react';

import { Divider } from '@chakra-ui/react';
import { useField } from 'react-final-form';
import { useFieldArray } from 'react-final-form-arrays';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import FormRow from 'components/FormRow';
import { IAssociatedPerson } from 'models/CompanyDetails.model';
import {
  USER_REPRESENTATIVE,
  REPRESENTATIVE_GENDER,
  REPRESENTATIVE_FIRST_NAME,
  REPRESENTATIVE_LAST_NAME,
  REPRESENTATIVE_EMAIL,
  COMPANY_DETAILS,
  COMPANY_DETAILS_EXISTING_USER_COMPANY,
  USER_EXISTING_REPRESENTATIVE,
  REPRESENTATIVE_BIRTHDATE,
  REPRESENTATIVE_PHONE_NUMBER,
  USER_REPRESENTATIVES_SELECT,
  USER_SOLE_REPRESENTATION,
  USER_REPRESENTATION,
  SELF_USER_REPRESENTATIVE,
} from 'modules/Inquiry/Form/formFields';
import { MultiSelectWithField } from 'modules/Inquiry/Form/Steps/RequestDetails/BankSearch/MultiSelect';
import { MultiSelectOption } from 'modules/Inquiry/Form/Steps/RequestDetails/BankSearch/MultiselectOption.model';
import { ParentFieldContext } from 'modules/InquiryFormNew/ParentField.context';
import RepresentativeBirthdate from 'pages/inquiryFlow/PeopleDetails/LegalRepresentationSection/fields/RepresentativeBirthdate';
import RepresentativeEmail from 'pages/inquiryFlow/PeopleDetails/LegalRepresentationSection/fields/RepresentativeEmail';
import RepresentativeFirstName from 'pages/inquiryFlow/PeopleDetails/LegalRepresentationSection/fields/RepresentativeFirstName';
import RepresentativeGender from 'pages/inquiryFlow/PeopleDetails/LegalRepresentationSection/fields/RepresentativeGender';
import RepresentativeLastName from 'pages/inquiryFlow/PeopleDetails/LegalRepresentationSection/fields/RepresentativeLastName';
import { LegalRepresentativesContext } from 'pages/inquiryFlow/PeopleDetails/LegalRepresentationSection/LegalRepresentatives/LegalRepresentatives.context';
import {
  StyledPeopleSection,
  StyledPeopleInfo,
  StyledSinglePerson,
  StyledSinglePersonFields,
  StyledRemoveButton,
} from 'pages/inquiryFlow/PeopleDetails/styles';
import { useFetchAssociatedPersons } from 'pages/operationPortal/CompaniesDetails/helpers/useFetchAssociatedPersons';
import { useFieldValidators } from 'shared/hooks/useFieldValidators';
import { getCompanyData } from 'store/inquiryDetails/selectors';
import { getStoredValueSelector } from 'store/progress/selectors';
import { ButtonComponent } from 'theme/components/Button';
import AddIcon from 'theme/components/Icon/AddIcon';
import DeleteIcon from 'theme/components/Icon/DeleteIcon';
import { formatDateDays } from 'utils/date';
import { useTranslations } from 'utils/hooks/useTranslations';

import { LegalRepresentativesWrapper } from './styles';
import { PeopleDetailsMultiSelectOption } from '../../MultiSelectPeopleDetails';
import RepresentativePhoneNumber from '../fields/RepresentativePhoneNumber';

export type FieldValue = {
  [REPRESENTATIVE_GENDER]: string;
  [REPRESENTATIVE_FIRST_NAME]: string;
  [REPRESENTATIVE_LAST_NAME]: string;
  [REPRESENTATIVE_EMAIL]: string;
  [REPRESENTATIVE_PHONE_NUMBER]: string;
  [REPRESENTATIVE_BIRTHDATE]: string;
  id: string;
  isAssociatedPerson?: boolean;
};

type Fields = Exclude<keyof FieldValue, 'id' | 'isAssociatedPerson'>;

type SinglePersonProps = {
  showDeleteButton?: boolean;
  onRemove?: () => void;
  visibleFields?: Fields[];
};

interface LegalRepsMultiSelectOption extends MultiSelectOption {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  gender: string;
  birthDate: Date;
  type: 'legal_representatives' | 'beneficiary_owners';
}

const SinglePerson = ({
  onRemove,
  showDeleteButton,
  visibleFields = [
    REPRESENTATIVE_GENDER,
    REPRESENTATIVE_FIRST_NAME,
    REPRESENTATIVE_LAST_NAME,
    REPRESENTATIVE_EMAIL,
    REPRESENTATIVE_PHONE_NUMBER,
    REPRESENTATIVE_BIRTHDATE,
  ],
}: SinglePersonProps) => {
  const t = useTranslations(
    'pages.peopleDetails.sections.legalRepresentation.fields.legalRepresentatives',
  );

  const handleRemove = (e: any) => {
    e.preventDefault();
    // This function wrapper ensures not to pass event as an argument to onRemove
    onRemove?.();
  };

  const { userIndex } = useContext(LegalRepresentativesContext);

  return (
    <StyledSinglePerson>
      <StyledSinglePersonFields>
        {showDeleteButton ? (
          <StyledRemoveButton
            onClick={handleRemove}
            aria-label={t('removePerson')}
            data-testid={`${USER_REPRESENTATIVE}[${userIndex}].remove-button`}
          >
            <DeleteIcon boxSize={6} />
          </StyledRemoveButton>
        ) : null}

        {visibleFields.includes(REPRESENTATIVE_GENDER) ? (
          <FormRow>
            <RepresentativeGender />
          </FormRow>
        ) : null}
        <FormRow>
          {visibleFields.includes(REPRESENTATIVE_FIRST_NAME) ? <RepresentativeFirstName /> : null}
          {visibleFields.includes(REPRESENTATIVE_LAST_NAME) ? <RepresentativeLastName /> : null}
        </FormRow>
        <FormRow>
          {visibleFields.includes(REPRESENTATIVE_EMAIL) ? <RepresentativeEmail /> : null}
        </FormRow>
        <FormRow>
          {visibleFields.includes(REPRESENTATIVE_PHONE_NUMBER) ? (
            <RepresentativePhoneNumber />
          ) : null}
        </FormRow>
        <FormRow>
          {visibleFields.includes(REPRESENTATIVE_BIRTHDATE) ? <RepresentativeBirthdate /> : null}
        </FormRow>
      </StyledSinglePersonFields>
    </StyledSinglePerson>
  );
};

interface LegalRepresentativesProps {
  selfLegalRepresentativeVisibleFields?: SinglePersonProps['visibleFields'];
}

const LegalRepresentatives = ({
  selfLegalRepresentativeVisibleFields,
}: LegalRepresentativesProps) => {
  const t = useTranslations(
    'pages.peopleDetails.sections.legalRepresentation.fields.legalRepresentatives',
  );
  const { fields: fieldsUserRepresentative } = useFieldArray<FieldValue>(USER_REPRESENTATIVE);
  const selectedCompany = useSelector<any, any>(getStoredValueSelector([COMPANY_DETAILS]));
  const companyData = useSelector(getCompanyData);
  const companyId = selectedCompany?.[COMPANY_DETAILS_EXISTING_USER_COMPANY]?.id || companyData?.id;
  const { fields: fieldsExistingRepresentative } = useFieldArray(USER_EXISTING_REPRESENTATIVE);
  const {
    input: { value: userRepresentation },
  } = useField(USER_REPRESENTATION);
  const {
    input: { value: userSoleRepresentation },
  } = useField(USER_SOLE_REPRESENTATION);

  const isUserSoleRepresentative =
    userRepresentation === 'true' && userSoleRepresentation === 'true';
  const { data, isLoading } = useFetchAssociatedPersons(companyId);
  const legalRepresentatives = data.filter(
    (p: IAssociatedPerson) => p.type === 'legal_representatives',
  );

  /*
  MultiSelect field does only work if used objects have a key property. Additionally
  the object needs a label since that is used for the displayed tags
  Without the key property you run into an eternal rerender
   */
  const legalRepresentativesOptions = legalRepresentatives.map<PeopleDetailsMultiSelectOption>(
    (person) => ({
      key: person.id,
      label: `${person.firstName} ${person.lastName}`,
      value: person.id,
      firstName: person.firstName,
      lastName: person.lastName,
      email: person.email,
      phoneNumber: person.phoneNumber,
      gender: person.salutation,
      birthDate: person.birthDate,
      type: person.type,
    }),
  );

  const handleAddPerson = () => {
    const representative = {
      [REPRESENTATIVE_GENDER]: '',
      [REPRESENTATIVE_FIRST_NAME]: '',
      [REPRESENTATIVE_LAST_NAME]: '',
      [REPRESENTATIVE_EMAIL]: '',
      [REPRESENTATIVE_PHONE_NUMBER]: '',
      [REPRESENTATIVE_BIRTHDATE]: '',
      id: uuidv4(),
    };

    fieldsUserRepresentative.push(representative);
  };

  useEffect(() => {
    if (
      !isLoading &&
      !isUserSoleRepresentative &&
      legalRepresentatives.length === 0 &&
      fieldsUserRepresentative.length === 0
    ) {
      handleAddPerson();
    }
  }, [legalRepresentatives, isUserSoleRepresentative, isLoading]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleRemovePerson = (index: number) => () => {
    const deletedLegalRep = fieldsUserRepresentative.value[index];
    fieldsUserRepresentative.remove(index);
    // Removes the person from fieldsExistingRepresentative if it exists
    const indexFieldsExistingRepresentative = fieldsExistingRepresentative.value?.findIndex(
      (representative) => representative.key === deletedLegalRep.id,
    );
    if (indexFieldsExistingRepresentative > -1) {
      fieldsExistingRepresentative.remove(indexFieldsExistingRepresentative);
    }
  };

  const hasAssociatedPerson = legalRepresentativesOptions.length > 0;
  const { required } = useFieldValidators();
  const [search, setSearch] = React.useState('');

  const handleSelect = (newPerson: LegalRepsMultiSelectOption) => {
    //REPRESENTATIVE_PHONE_NUMBER
    // wow
    const representative = {
      [REPRESENTATIVE_GENDER]: newPerson.gender,
      [REPRESENTATIVE_FIRST_NAME]: newPerson.firstName,
      [REPRESENTATIVE_LAST_NAME]: newPerson.lastName,
      [REPRESENTATIVE_EMAIL]: newPerson.email,
      [REPRESENTATIVE_BIRTHDATE]: formatDateDays(newPerson.birthDate),
      [REPRESENTATIVE_PHONE_NUMBER]: newPerson.phoneNumber,
      id: newPerson.value.toString(),
      isAssociatedPerson: true,
    };
    fieldsUserRepresentative.push(representative);
  };

  const handleRemove = (keyToRemove: string) => {
    const itemExistsIndex = fieldsExistingRepresentative.value?.findIndex(
      ({ key }) => key === keyToRemove,
    );

    fieldsExistingRepresentative.remove(itemExistsIndex > -1 ? itemExistsIndex : 0);

    const indexToRemove = fieldsUserRepresentative.value.findIndex(
      (representative) => representative.id === keyToRemove,
    );
    if (indexToRemove > -1) {
      fieldsUserRepresentative.remove(indexToRemove);
    }
  };

  const togglePerson = (person: LegalRepsMultiSelectOption) => {
    if (fieldsExistingRepresentative.value?.findIndex(({ key }) => key === person.key) > -1) {
      handleRemove(person.key);
    } else {
      handleSelect(person);
    }
  };

  const handleSearch = (value: string) => {
    setSearch(value);
  };

  const handleValidate = (
    value: unknown,
    fields: {
      [USER_REPRESENTATIVE]?: unknown[];
    },
  ) => {
    const field = fields[USER_REPRESENTATIVE];
    const isAdditionalPerson = field && field?.length > 0;
    return isAdditionalPerson ? undefined : required(value, fields);
  };

  const options = legalRepresentativesOptions.filter(({ label }) =>
    label.toLocaleLowerCase().includes(search.toLowerCase()),
  );

  return (
    <StyledPeopleSection>
      <Divider opacity="1" mt={4} mb={8} borderColor="border.lightGrey" />
      <StyledPeopleInfo>{t('heading')}</StyledPeopleInfo>
      {hasAssociatedPerson && (
        <MultiSelectWithField
          name={USER_EXISTING_REPRESENTATIVE}
          placeholder={t('placeholders.pleaseChoose')}
          options={options}
          validate={handleValidate}
          selectedOptions={fieldsExistingRepresentative.value || []}
          isLoading={false}
          onSelect={togglePerson}
          onRemove={handleRemove}
          onInputChange={handleSearch}
          caption={t('existingLegalRepresentatives')}
          testId={USER_REPRESENTATIVES_SELECT}
        />
      )}
      {isUserSoleRepresentative ? (
        <LegalRepresentativesContext.Provider
          value={{ userIndex: 0, fieldName: SELF_USER_REPRESENTATIVE }}
        >
          <LegalRepresentativesWrapper>
            <SinglePerson visibleFields={selfLegalRepresentativeVisibleFields} />
          </LegalRepresentativesWrapper>
        </LegalRepresentativesContext.Provider>
      ) : null}
      {fieldsUserRepresentative.map((fieldName, index) => (
        <LegalRepresentativesWrapper key={index}>
          <ParentFieldContext.Provider
            value={{
              parentFieldName: '',
              parentIndex: 0,
            }}
            key={fieldsUserRepresentative.value[index].id}
          >
            <LegalRepresentativesContext.Provider value={{ userIndex: index, fieldName }}>
              <SinglePerson
                onRemove={handleRemovePerson(index)}
                showDeleteButton={
                  fieldsUserRepresentative.length ? fieldsUserRepresentative.length > 1 : false
                }
              />
            </LegalRepresentativesContext.Provider>
          </ParentFieldContext.Provider>
        </LegalRepresentativesWrapper>
      ))}
      <StyledPeopleInfo>{t('footer')}</StyledPeopleInfo>
      <ButtonComponent
        leftIcon={<AddIcon boxSize={6} display="block" />}
        onClick={handleAddPerson}
        data-testid="add-additional-legal-representative-btn"
      >
        {t('addPerson')}
      </ButtonComponent>
    </StyledPeopleSection>
  );
};

export default React.memo(LegalRepresentatives);
