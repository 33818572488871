import React from 'react';

import { Text } from '@chakra-ui/react';
import _isEmpty from 'lodash/isEmpty';
import { Form } from 'react-final-form';
import { useSelector } from 'react-redux';

import Condition from 'components/Condition';
import { DayPickerWithField } from 'components/DatePicker';
import FormRow from 'components/FormRow';
import FormSection from 'components/FormSection';
import { InputWithField } from 'components/Input';
import { SelectWithField } from 'components/Selects/Select';
import { TextAreaWithField } from 'components/TextArea';
import EditMarketValue, {
  SectionType,
} from 'modules/EditMarketValuesReeProfitability/EditMarketValueRee.type';
import { unknownToString } from 'modules/EditMarketValuesReeProfitability/mapProfitabilitySectionsToApi/utils';
import BottomBar from 'modules/FormPage/BottomBar';
import ButtonsWrapper from 'pages/inquiryFlow/FinalPage/SuccessButtons/ButtonsWrapper';
import { getInquiryIdSelector } from 'store/inquiryDetails/selectors';
import { TextComponent } from 'theme/components/Text';
import { textStyle } from 'theme/themeConstants';
import { useTranslations } from 'utils/hooks/useTranslations';
import { scrollTop } from 'utils/scroll';
import { formatPrice } from 'utils/valueFormats';

import EditPageHeading from './EditPageHeading';
import PrimaryButton from './PrimaryButton';
import TertiaryButton from './TertiaryButton';
import { EditPageProps } from './types';

type FieldType = {
  name: string;
  type: string;
  caption?: string;
  placeholder?: string;
  defaultValue?: string;
  options?: {
    value: string;
    label: string;
  }[];
};

type FieldOrFieldNameType = string | FieldType;

const EditPage = ({
  condition = () => false,
  editFormStructure,
  handleEditFormSubmit,
  getFieldsType,
  additionalNote = '',
  additionalNoteValue = '',
  recordType,
  loadData,
}: EditPageProps) => {
  const t = useTranslations();
  const [stepNumber, setStepNumber] = React.useState(0);
  const [displayPage, setDisplayPage] = React.useState<EditMarketValue>(editFormStructure[0]);
  const [formValues, setFormValues] = React.useState<Record<string, unknown>>({});

  const inquiryId = useSelector(getInquiryIdSelector) || '';

  React.useEffect(() => {
    scrollTop();
  }, [displayPage]);

  React.useEffect(() => {
    async function fetchData() {
      await Promise.resolve(loadData(inquiryId, recordType)).then((values) => {
        return setFormValues(values);
      });
    }
    fetchData();
  }, [loadData, inquiryId, recordType]);

  let marketValues: Record<string, unknown> = {};
  const marketValueKeys: Array<FieldOrFieldNameType> = editFormStructure.flatMap((pageStructure) =>
    pageStructure.sections.flatMap((section) =>
      section.fields.map((field: FieldOrFieldNameType) =>
        typeof field === 'string' ? field : field.name,
      ),
    ),
  );

  Object.keys(formValues)
    .filter((key) => marketValueKeys.includes(key))
    .forEach((key) => {
      marketValues[key] = formValues[key];
    });

  const buttonProps = {
    stepNumber,
    editFormStructure,
    setDisplayPage,
    setStepNumber,
  };

  const getFields = (field: FieldOrFieldNameType) => {
    if (typeof field === 'object' && field.type === 'dropdown') {
      return (
        <SelectWithField
          name={field.name}
          options={field.options?.map(({ label, value }) => ({ label: t(label), value }))}
          caption={t(field.caption)}
          initial={formValues[field.name]}
        />
      );
    }
    if (typeof field === 'object' && field.type === 'text') {
      const value =
        _isEmpty(formValues[field.name]) && field?.defaultValue
          ? t(field.defaultValue)
          : formValues[field.name];
      return (
        <TextComponent {...textStyle.sizeXs} as="span" display="block" color="text.tertiary">
          {`${t(field.caption)} ${value}`}
        </TextComponent>
      );
    }
    if (typeof field === 'object' && field.type === 'date') {
      const date = formValues[field.name] as string;
      return (
        <DayPickerWithField
          name={field.name}
          caption={t(field.caption)}
          selected={date ? new Date(date.split('.').reverse().join('-')) : undefined}
        />
      );
    }
    if (typeof field === 'object' && field.type === 'textArea') {
      return (
        <TextAreaWithField
          name={field.name}
          placeholder={t(field.placeholder)}
          caption={t(field.caption)}
        />
      );
    }
    if (typeof field === 'string') {
      return <InputWithField name={field} type={getFieldsType(field)} />;
    }
  };
  return (
    <Form
      onSubmit={handleEditFormSubmit}
      initialValues={marketValues}
      render={({ handleSubmit }) => (
        <form>
          <EditPageHeading
            heading={displayPage?.heading || ''}
            subHeading={displayPage?.subHeading || ''}
          />
          {displayPage?.sections.map((section: SectionType, sectionIndex: number) => (
            <FormSection
              sectionNumber={sectionIndex + 1}
              key={sectionIndex}
              title={t(section.title)}
            >
              {section.fields.map((field: FieldOrFieldNameType, fieldIndex: number) => (
                <>
                  <FormRow key={fieldIndex}>{getFields(field)}</FormRow>
                  <Condition condition={condition(typeof field === 'string' ? field : '')}>
                    <FormRow>
                      <Text>
                        {t(additionalNote, {
                          amount: formatPrice(unknownToString(formValues[additionalNoteValue])),
                        })}
                      </Text>
                    </FormRow>
                  </Condition>
                </>
              ))}
            </FormSection>
          ))}
          <BottomBar>
            <ButtonsWrapper>
              <TertiaryButton {...buttonProps} />
              <PrimaryButton {...buttonProps} onClick={handleSubmit} />
            </ButtonsWrapper>
          </BottomBar>
        </form>
      )}
    />
  );
};

export default EditPage;
