import React, { useEffect } from 'react';

import { Flex } from '@chakra-ui/react';
import { useForm } from 'react-final-form';
import { FieldArrayRenderProps } from 'react-final-form-arrays';

import Separator from 'components/FormRow/Separator';
import { useShowBeneficialOwnersSection } from 'dzb/inquiry/config/conditions';
import { dzbFieldsWithSectionString } from 'dzb/inquiry/fields';
import { translations } from 'new/form/common/types';
import { BeneficialOwnerFields } from 'pages/operationPortal/CompaniesDetails/AssiciatedPerson/components/beneficialOwners/BeneficialOwnerFields';
import ButtonComponent from 'theme/components/Button';
import DeleteIcon from 'theme/components/Icon/DeleteIcon';
import { TextComponent } from 'theme/components/Text';
import { useTranslations } from 'utils/hooks/useTranslations';

const arrayFieldName =
  dzbFieldsWithSectionString.personalDataPage.beneficialOwnersSection.beneficialOwners
    .sectionString;

export const BeneficialOwnersCards = ({
  fields,
}: {
  fields: FieldArrayRenderProps<any, any>['fields'];
}) => {
  const { mutators } = useForm();
  const t = useTranslations();
  const showSection = useShowBeneficialOwnersSection();
  const {
    subheadings: { additionalInformation },
  } = translations.inquiryType.dzb.pages.personalData.sections.beneficialOwners;

  useEffect(() => {
    if (fields.length === 0) {
      mutators.push(arrayFieldName);
    }
  }, [fields.length, mutators]);

  if (!fields) {
    return null;
  }

  if (showSection) {
    return (
      <>
        <TextComponent color={'text.tertiary'} mb={8}>
          {t(additionalInformation)}
        </TextComponent>
        {fields.map((name, index) => {
          const beneficialOwner = fields.value[index];
          const fieldLength = fields.length;
          return (
            <>
              <Flex
                width={'full'}
                key={name}
                data-aos={'fade-bottom'}
                data-aos-delay={100}
                flexDirection={'column'}
                backgroundColor={'background.lightGrey'}
                borderRadius={5}
                borderColor={'border.lightGrey'}
                borderWidth={1}
                borderStyle={'solid'}
                p={6}
              >
                <ButtonComponent
                  alignSelf={'flex-end'}
                  leftIcon={<DeleteIcon boxSize={4} display={'block'} />}
                  onClick={() => fields.remove(index)}
                  variant={'secondary'}
                >
                  {t(
                    translations.inquiryType.dzb.pages.personalData.sections.beneficialOwners.fields
                      .beneficialOwner.remove,
                  )}
                </ButtonComponent>
                {beneficialOwner?.id ? (
                  <BeneficialOwnerFields mode={'edit'} prefix={name} />
                ) : (
                  <BeneficialOwnerFields mode={'add'} prefix={name} />
                )}
              </Flex>
              {fieldLength ? index < fieldLength - 1 && <Separator /> : null}
            </>
          );
        })}
      </>
    );
  }

  return null;
};
