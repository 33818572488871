import * as React from 'react';

import { Center, Grid, GridItem, UnorderedList } from '@chakra-ui/react';

import { Asset } from 'components/Asset';
import MarkdownWrapper from 'components/MarkdownWrapper/MarkdownWrapper';
import { SIGN_CONTRACT_BUTTON } from 'modules/Inquiry/Form/formFields';
import { translations } from 'new/form/common/types';
import { ButtonComponent } from 'theme/components/Button';
import { HeadingComponent } from 'theme/components/Heading';
import ArrowRightIcon from 'theme/components/Icon/ArrowRightIcon';
import { ProgressSection } from 'theme/components/ProgressSection';
import { TextComponent } from 'theme/components/Text';
import { useTranslations } from 'utils/hooks/useTranslations';

import { ProgressSectionType } from './types';

const { description, list, button, buttonPending } =
  translations.pages.inquiryDetails.dashboard.actions.videoIdent;

type ProgressVideoIdentProps = {
  isFirst?: boolean;
  isLast?: boolean;
  isCompleted?: boolean;
  isPending?: boolean;
  completedValue?: number;
  progressIncrement?: number;
  disableButton?: boolean;
  progressTotal?: number;
  actionUrl?: string;
  id?: string;
};

export const ProgressVideoIdent = ({
  isFirst,
  isLast,
  isCompleted,
  isPending,
  completedValue,
  progressIncrement,
  disableButton,
  progressTotal,
  actionUrl,
  id,
}: ProgressVideoIdentProps) => {
  const t = useTranslations();

  return (
    <ProgressSection
      isFirst={isFirst}
      isLast={isLast}
      isCompleted={isCompleted}
      completedValue={completedValue}
      progressIncrement={progressIncrement}
      progressTotal={progressTotal}
      id={id}
    >
      <Grid templateColumns={['repeat(1, 1fr)', null, '2fr 1fr']} gap={12}>
        <GridItem width={{ base: '75%', sm: '100%', md: '100%' }}>
          <HeadingComponent as="h4" color="brand.default" mb={2} variant="secondary">
            {t('header')}
          </HeadingComponent>
          <TextComponent mb={3}>{t(description)}</TextComponent>
          <UnorderedList mb={6}>
            <MarkdownWrapper text={t(list)} />
          </UnorderedList>
          {actionUrl && !isCompleted && (
            <ButtonComponent
              as="a"
              href={disableButton ? undefined : actionUrl}
              target="_blank"
              leftIcon={<ArrowRightIcon boxSize={6} display="block" />}
              variant="primary"
              size="md"
              data-testid={SIGN_CONTRACT_BUTTON}
              disabled={disableButton}
            >
              {t(button)}
            </ButtonComponent>
          )}
          {!actionUrl && isPending && (
            <ButtonComponent
              disabled
              leftIcon={<ArrowRightIcon boxSize={6} display="block" />}
              variant="primary"
            >
              {t(buttonPending)}
            </ButtonComponent>
          )}
        </GridItem>

        <GridItem>
          <Center height="100%">
            <Asset type="dashboard" value={ProgressSectionType.VIDEO_IDENT} />
          </Center>
        </GridItem>
      </Grid>
    </ProgressSection>
  );
};
