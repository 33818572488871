import React, { useContext } from 'react';

import { PhoneNumberField, PhoneNumberFieldWithOnMountError } from 'components/PhoneNumberField';
import {
  REPRESENTATIVE_PHONE_NUMBER,
  SELF_USER_REPRESENTATIVE,
} from 'modules/Inquiry/Form/formFields';
import { LegalRepresentativesContext } from 'pages/inquiryFlow/PeopleDetails/LegalRepresentationSection/LegalRepresentatives/LegalRepresentatives.context';
import { useFieldValidators } from 'shared/hooks/useFieldValidators';
import { combineValidators } from 'utils/validators';

const RepresentativePhoneNumber: React.FC = () => {
  const { fieldName } = useContext(LegalRepresentativesContext);
  const { required, isPhoneNumberReal } = useFieldValidators();
  const isSelfRepresentative = fieldName === SELF_USER_REPRESENTATIVE;

  const commonProps = {
    initialValue: null,
    name: `${fieldName}.${REPRESENTATIVE_PHONE_NUMBER}`,
    autoComplete: 'tel',
    validate: combineValidators(isPhoneNumberReal, required),
  };

  return (
    <>
      {isSelfRepresentative ? (
        <PhoneNumberFieldWithOnMountError {...commonProps} />
      ) : (
        <PhoneNumberField {...commonProps} />
      )}
    </>
  );
};

export default RepresentativePhoneNumber;
