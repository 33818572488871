const OPERATION_CACHE_KEYS = {
  marketDataKey: 'MARKET_DATA_KEY',
  marketDataReeKey: 'MARKET_DATA_REE_KEY',
  profitabilityDataKey: 'PROFITABILITY_DATA_KEY',
  internalCommentsKey: 'INTERNAL_COMMENTS_KEY',
  processLogKey: 'PROCESS_LOG_KEY',
  inquiryRevisionKey: 'INQUIRY_REVISION_KEY',
  inquiryRevisionsKey: 'INQUIRY_REVISIONS_KEY',
  inquiriesListKey: 'INQUIRIES_LIST_KEY',
};

export default OPERATION_CACHE_KEYS;
