import React, { useCallback } from 'react';

import { Divider } from '@chakra-ui/react';
import { useField } from 'react-final-form';
import { useFieldArray } from 'react-final-form-arrays';
import { useSelector } from 'react-redux';

import FormRow from 'components/FormRow';
import { IAssociatedPerson } from 'models/CompanyDetails.model';
import {
  COMPANY_DETAILS,
  COMPANY_DETAILS_EXISTING_USER_COMPANY,
  REPRESENTATIVE_BIRTHDATE,
  REPRESENTATIVE_EMAIL,
  REPRESENTATIVE_FIRST_NAME,
  REPRESENTATIVE_GENDER,
  REPRESENTATIVE_LAST_NAME,
  REPRESENTATIVE_PHONE_NUMBER,
  SELF_USER_REPRESENTATIVE,
  USER_EXISTING_REPRESENTATIVE,
  USER_REPRESENTATION,
  USER_REPRESENTATIVE,
  USER_REPRESENTATIVES_SELECT,
  USER_SOLE_REPRESENTATION,
} from 'modules/Inquiry/Form/formFields';
import { MultiSelectWithField } from 'modules/Inquiry/Form/Steps/RequestDetails/BankSearch/MultiSelect';
import { MultiSelectOption } from 'modules/Inquiry/Form/Steps/RequestDetails/BankSearch/MultiselectOption.model';
import { ParentFieldContext } from 'modules/InquiryFormNew/ParentField.context';
import { LegalRepresentativesContext } from 'pages/inquiryFlow/PeopleDetails/LegalRepresentationSection/LegalRepresentatives/LegalRepresentatives.context';
import { PeopleDetailsMultiSelectOption } from 'pages/inquiryFlow/PeopleDetails/MultiSelectPeopleDetails';
import {
  StyledPeopleInfo,
  StyledPeopleSection,
  StyledRemoveButton,
  StyledSinglePerson,
  StyledSinglePersonFields,
} from 'pages/inquiryFlow/PeopleDetails/styles';
import { useFetchAssociatedPersons } from 'pages/operationPortal/CompaniesDetails/helpers/useFetchAssociatedPersons';
import { useFieldValidators } from 'shared/hooks/useFieldValidators';
import { getCompanyData } from 'store/inquiryDetails/selectors';
import { getStoredValueSelector } from 'store/progress/selectors';
import { ButtonComponent } from 'theme/components/Button';
import AddIcon from 'theme/components/Icon/AddIcon';
import DeleteIcon from 'theme/components/Icon/DeleteIcon';
import { formatDateDays } from 'utils/date';
import { useTranslations } from 'utils/hooks/useTranslations';

import RepresentativeBirthdate from './fields/RepresentativeBirthdate';
import RepresentativeEmail from './fields/RepresentativeEmail';
import RepresentativeFirstName from './fields/RepresentativeFirstName';
import RepresentativeGender from './fields/RepresentativeGender';
import RepresentativeLastName from './fields/RepresentativeLastName';
import RepresentativePhoneNumber from './fields/RepresentativePhoneNumber';
import { LegalRepresentativesWrapper, StyledEntityFormHeading } from './styles';
import { useAutoAddRepresentative } from './useAutoAddRepresentative';
import { useAutoFillRepresentativeData } from './useAutoFillRepresentativeData';

export type FieldValue = {
  [REPRESENTATIVE_GENDER]: string;
  [REPRESENTATIVE_FIRST_NAME]: string;
  [REPRESENTATIVE_LAST_NAME]: string;
  [REPRESENTATIVE_EMAIL]: string;
  [REPRESENTATIVE_PHONE_NUMBER]: string;
  [REPRESENTATIVE_BIRTHDATE]: string;
  id?: string;
  isAssociatedPerson?: boolean;
};

type Fields = Exclude<keyof FieldValue, 'id' | 'isAssociatedPerson'>;

type SinglePersonProps = {
  showDeleteButton?: boolean;
  onRemove?: () => void;
  visibleFields?: Fields[];
  index?: number;
  /**
   * Keep in mind, that this does not disable all the fields - the phone number will be still enabled (user story requirement)
   */
  isDisabled?: boolean;
};

interface LegalRepsMultiSelectOption extends MultiSelectOption {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  gender: string;
  birthDate: Date;
  type: 'legal_representatives' | 'beneficiary_owners';
}

const SinglePerson = ({
  onRemove,
  showDeleteButton,
  index,
  visibleFields = [
    REPRESENTATIVE_GENDER,
    REPRESENTATIVE_FIRST_NAME,
    REPRESENTATIVE_LAST_NAME,
    REPRESENTATIVE_EMAIL,
    REPRESENTATIVE_PHONE_NUMBER,
    REPRESENTATIVE_BIRTHDATE,
  ],
  isDisabled = false,
}: SinglePersonProps) => {
  const t = useTranslations(
    'pages.peopleDetails.sections.legalRepresentation.fields.legalRepresentatives',
  );

  const handleRemove = (e: any) => {
    e.preventDefault();
    // This function wrapper ensures not to pass event as an argument to onRemove
    onRemove?.();
  };

  return (
    <StyledSinglePerson>
      <StyledSinglePersonFields>
        <div>
          {index !== undefined ? (
            <StyledEntityFormHeading>
              {t('formHeading')} {index + 1}
            </StyledEntityFormHeading>
          ) : null}
          {showDeleteButton ? (
            <StyledRemoveButton
              onClick={handleRemove}
              aria-label={t('removePerson')}
              data-testid={`${USER_REPRESENTATIVE}[${index}].remove-button`}
            >
              <DeleteIcon boxSize={6} />
            </StyledRemoveButton>
          ) : null}
        </div>

        {visibleFields.includes(REPRESENTATIVE_GENDER) ? (
          <FormRow>
            <RepresentativeGender isDisabled={isDisabled} />
          </FormRow>
        ) : null}
        <FormRow>
          {visibleFields.includes(REPRESENTATIVE_FIRST_NAME) ? (
            <RepresentativeFirstName isDisabled={isDisabled} />
          ) : null}
          {visibleFields.includes(REPRESENTATIVE_LAST_NAME) ? (
            <RepresentativeLastName isDisabled={isDisabled} />
          ) : null}
        </FormRow>
        <FormRow>
          {visibleFields.includes(REPRESENTATIVE_EMAIL) ? (
            <RepresentativeEmail isDisabled={isDisabled} />
          ) : null}
        </FormRow>
        <FormRow>
          {visibleFields.includes(REPRESENTATIVE_PHONE_NUMBER) ? (
            // Only the phone number is allowed to be edited manually by user, when the representative exists
            <RepresentativePhoneNumber />
          ) : null}
        </FormRow>
        <FormRow>
          {visibleFields.includes(REPRESENTATIVE_BIRTHDATE) ? (
            <RepresentativeBirthdate isDisabled={isDisabled} />
          ) : null}
        </FormRow>
      </StyledSinglePersonFields>
    </StyledSinglePerson>
  );
};

interface LegalRepresentativesProps {
  selfLegalRepresentativeVisibleFields?: SinglePersonProps['visibleFields'];
}

const LegalRepresentatives = ({
  selfLegalRepresentativeVisibleFields,
}: LegalRepresentativesProps) => {
  const t = useTranslations(
    'pages.peopleDetails.sections.legalRepresentation.fields.legalRepresentatives',
  );
  const { fields: fieldsUserRepresentative } = useFieldArray<FieldValue>(USER_REPRESENTATIVE);

  const selectedCompany = useSelector<any, any>(getStoredValueSelector([COMPANY_DETAILS]));
  const companyData = useSelector(getCompanyData);
  const companyId = selectedCompany?.[COMPANY_DETAILS_EXISTING_USER_COMPANY]?.id || companyData?.id;
  const { fields: fieldsExistingRepresentative } = useFieldArray(USER_EXISTING_REPRESENTATIVE);
  const userRepresentationField = useField(USER_REPRESENTATION);
  const {
    input: { value: userRepresentation },
  } = userRepresentationField;
  const userSharedRepresentationField = useField(USER_SOLE_REPRESENTATION);
  const {
    input: { value: userSharedRepresentation },
  } = userSharedRepresentationField;

  const isUserAllowedToRepresent = userRepresentation === 'true';
  const isUserAllowedToRepresentAlone = userSharedRepresentation === 'true';

  const { data } = useFetchAssociatedPersons(companyId);
  const legalRepresentatives = data.filter(
    (p: IAssociatedPerson) => p.type === 'legal_representatives',
  );

  /*
  MultiSelect field does only work if used objects have a key property. Additionally
  the object needs a label since that is used for the displayed tags
  Without the key property you run into an eternal rerender
   */
  const associatedPersons = legalRepresentatives
    .map<PeopleDetailsMultiSelectOption>((person) => ({
      key: person.id,
      label: `${person.firstName} ${person.lastName}`,
      value: person.id,
      firstName: person.firstName,
      lastName: person.lastName,
      email: person.email,
      phoneNumber: person.phoneNumber,
      gender: person.salutation,
      birthDate: person.birthDate,
      type: person.type,
    }))
    .filter((p) => p.type === 'legal_representatives');

  const handleAddPerson = useCallback(() => {
    const representative = {
      [REPRESENTATIVE_GENDER]: '',
      [REPRESENTATIVE_FIRST_NAME]: '',
      [REPRESENTATIVE_LAST_NAME]: '',
      [REPRESENTATIVE_EMAIL]: '',
      [REPRESENTATIVE_PHONE_NUMBER]: '',
      [REPRESENTATIVE_BIRTHDATE]: '',
    };

    fieldsUserRepresentative.push(representative);
  }, [fieldsUserRepresentative]);

  const handelRemovePerson = (index: number) => () => {
    const singleLegalRep = fieldsUserRepresentative.value[index];
    fieldsUserRepresentative.remove(index);
    // All representatives that are prefilled, has an id property in the object, linking the representative with the database entry
    // If singleLegalRep does not have an id property, it means that it was filled by user manually
    if (!singleLegalRep.id) {
      return;
    }
    // Find an index of the representative in the array of existing representatives (prefilled ones)
    // In order to remove it from the array
    const indexFieldsExistingRepresentative = fieldsExistingRepresentative.value?.findIndex(
      (representative) => representative.key === singleLegalRep.id,
    );
    // If we can't find it, it means something went wrong, but we ignore it.
    if (
      indexFieldsExistingRepresentative === undefined ||
      indexFieldsExistingRepresentative <= -1
    ) {
      return;
    }
    fieldsExistingRepresentative.remove(indexFieldsExistingRepresentative);
  };

  const hasAssociatedPersons = associatedPersons.length > 0;
  const { required } = useFieldValidators();
  const [search, setSearch] = React.useState('');

  const handleSelect = (newPerson: LegalRepsMultiSelectOption) => {
    //REPRESENTATIVE_PHONE_NUMBER
    // wow
    const representative = {
      [REPRESENTATIVE_GENDER]: newPerson.gender,
      [REPRESENTATIVE_FIRST_NAME]: newPerson.firstName,
      [REPRESENTATIVE_LAST_NAME]: newPerson.lastName,
      [REPRESENTATIVE_EMAIL]: newPerson.email,
      [REPRESENTATIVE_BIRTHDATE]: formatDateDays(newPerson.birthDate),
      [REPRESENTATIVE_PHONE_NUMBER]: newPerson.phoneNumber,
      id: newPerson.value.toString(),
      isAssociatedPerson: true,
    };
    fieldsUserRepresentative.push(representative);
  };

  const handleRemove = (keyToRemove: string) => {
    const itemExistsIndex = fieldsExistingRepresentative.value?.findIndex(
      ({ key }) => key === keyToRemove,
    );

    fieldsExistingRepresentative.remove(itemExistsIndex > -1 ? itemExistsIndex : 0);

    const indexToRemove = fieldsUserRepresentative.value.findIndex(
      (representative) => representative.id === keyToRemove,
    );
    if (indexToRemove > -1) {
      fieldsUserRepresentative.remove(indexToRemove);
    }
  };

  const togglePerson = (person: LegalRepsMultiSelectOption) => {
    if (fieldsExistingRepresentative.value?.findIndex(({ key }) => key === person.key) > -1) {
      handleRemove(person.key);
    } else {
      handleSelect(person);
    }
  };

  const handleSearch = (value: string) => {
    setSearch(value);
  };

  const handleValidate = (
    value: unknown,
    fields: {
      [USER_REPRESENTATIVE]?: unknown[];
    },
  ) => {
    const field = fields[USER_REPRESENTATIVE];
    const isAdditionalPerson = field && field?.length > 0;
    return isAdditionalPerson || isUserAllowedToRepresent ? undefined : required(value, fields);
  };

  const options = associatedPersons.filter(({ label }) =>
    label.toLocaleLowerCase().includes(search.toLowerCase()),
  );

  const selectedOptions =
    associatedPersons.filter((person) =>
      fieldsUserRepresentative.value?.find((p) => p.id === person.key),
    ) || [];

  useAutoFillRepresentativeData(isUserAllowedToRepresent);

  useAutoAddRepresentative({
    userRepresentationField,
    userSharedRepresentationField,
    onAdd: handleAddPerson,
    legalRepresentativesCount: legalRepresentatives.length + (fieldsUserRepresentative.length || 0),
  });

  return (
    <StyledPeopleSection>
      {isUserAllowedToRepresent ? (
        <LegalRepresentativesContext.Provider
          value={{ userIndex: 0, fieldName: SELF_USER_REPRESENTATIVE }}
        >
          <SinglePerson visibleFields={selfLegalRepresentativeVisibleFields} />
          <Divider opacity="1" mt={4} mb={8} borderColor="border.lightGrey" />
        </LegalRepresentativesContext.Provider>
      ) : null}
      {userRepresentation && hasAssociatedPersons ? (
        <MultiSelectWithField
          name={USER_EXISTING_REPRESENTATIVE}
          placeholder={t('placeholders.pleaseChoose')}
          options={options}
          validate={handleValidate}
          selectedOptions={selectedOptions}
          isLoading={false}
          onSelect={togglePerson}
          onRemove={handleRemove}
          onInputChange={handleSearch}
          caption={t('existingLegalRepresentatives')}
          testId={USER_REPRESENTATIVES_SELECT}
        />
      ) : null}
      {userRepresentation === 'false' || userRepresentation === 'true' ? (
        <>
          <Divider opacity="1" mt={4} mb={8} borderColor="border.lightGrey" />
          <StyledPeopleInfo>{t('heading')}</StyledPeopleInfo>
        </>
      ) : null}
      {fieldsUserRepresentative.map((fieldName, index) => {
        return (
          <>
            <LegalRepresentativesWrapper>
              <ParentFieldContext.Provider
                value={{
                  parentFieldName: '',
                  parentIndex: 0,
                }}
                key={fieldsUserRepresentative.value[index].id}
              >
                <LegalRepresentativesContext.Provider value={{ userIndex: -1, fieldName }}>
                  <SinglePerson
                    onRemove={handelRemovePerson(index)}
                    index={index}
                    isDisabled={fieldsUserRepresentative.value?.[index]?.isAssociatedPerson}
                    showDeleteButton={
                      (isUserAllowedToRepresent && isUserAllowedToRepresentAlone) ||
                      fieldsUserRepresentative?.value?.length > 1
                        ? true
                        : index !== 0
                    }
                  />
                </LegalRepresentativesContext.Provider>
              </ParentFieldContext.Provider>
            </LegalRepresentativesWrapper>
          </>
        );
      })}

      {userRepresentation === 'false' || userRepresentation === 'true' ? (
        <>
          {fieldsUserRepresentative?.value?.length >= 1 ? (
            <StyledPeopleInfo>{t('footer')}</StyledPeopleInfo>
          ) : null}
          <ButtonComponent
            leftIcon={<AddIcon boxSize={6} display="block" />}
            onClick={handleAddPerson}
            data-testid="add-additional-legal-representative-btn"
          >
            {t('addPerson')}
          </ButtonComponent>
        </>
      ) : null}
    </StyledPeopleSection>
  );
};

export default React.memo(LegalRepresentatives);
