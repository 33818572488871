import env from '@beam-australia/react-env';
import Axios, {
  AxiosInstance,
  AxiosRequestHeaders,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from 'axios';

import { ApiAuthTokenReponse } from 'models/ApiAuthTokenResponse.model';
import { getHeadersWithAuthToken, getRefreshToken } from 'utils/auth';
import { trackUserLogout } from 'utils/tracker/actions/trackUserId';
import { getUserScope } from 'utils/user/getters';
import { resetUser } from 'utils/user/setters';

import authEndpoints from './AuthApi/endpoints';
import { updateUserData } from './AuthApi/queryHooks/auth';

export const WHITE_LABEL_API_CLIENT_NAME = 'whiteLabel';
export const COMPEON_API_CLIENT_NAME = 'compeon';
export const AUTH_API_CLIENT_NAME = 'auth';
export const COMPEON_OAUTH_API_CLIENT_NAME = 'compeonOAuth';
export const MOCK_API_CLIENT_NAME = 'mockAPI';

const authedRequestInterceptor = (config: InternalAxiosRequestConfig<any>) => {
  config.headers = getHeadersWithAuthToken(config.headers) as AxiosRequestHeaders;
  return config;
};

let tokenRefreshPromise: Promise<AxiosResponse<ApiAuthTokenReponse>> | undefined;
const authedResponseExpiredInterceptor = async (error: any) => {
  const { config: originalRequest } = error;
  const refreshToken = getRefreshToken();
  const scope = getUserScope();
  if (error.response.status === 401 && !originalRequest._retry && refreshToken && scope) {
    originalRequest._retry = true;

    try {
      if (!tokenRefreshPromise) {
        // Creates one token refresh promise so we don't run into trouble having two requests
        tokenRefreshPromise = AuthClientInstance.post<ApiAuthTokenReponse>(
          authEndpoints.LOG_IN.compose(),
          {
            refresh_token: refreshToken,
            grant_type: 'refresh_token',
            scope,
          },
        );
      }
      // Awaits the refresh promise for every request. This has to be done since there can be multiple simultaneous interceptor calls.
      const response = await tokenRefreshPromise;
      tokenRefreshPromise = undefined;

      if (response.status === 200) {
        // Store new tokens in user session storage
        updateUserData(response, true);
      } else {
        throw new Error(response.statusText);
      }

      // Update the original request header
      originalRequest.headers = getHeadersWithAuthToken(originalRequest.headers);
      // Retry the original request and return the response
      return await EcoBankingAxiosClientAuthedInstance(originalRequest);
    } catch (err: any) {
      trackUserLogout();
      resetUser();
      throw error;
    }
  }
  throw error;
};

// Being used because we need a first argument for the response interceptor
const noopResponseHandler = (response: any) => response;

class MockApiClient {
  private static instance: AxiosInstance;

  public static getInstance(useInterceptors: boolean = true) {
    if (!MockApiClient.instance) {
      MockApiClient.instance = Axios.create({
        baseURL: '/',
        responseType: 'json',
      });
    }

    if (useInterceptors) {
      MockApiClient.setupInterceptors();
    } else {
      MockApiClient.instance.interceptors.request.clear();
      MockApiClient.instance.interceptors.response.clear();
    }

    return MockApiClient.instance;
  }

  private static setupInterceptors() {}
}

export class CompeonReverseClient {
  private static instance: AxiosInstance;

  public static getInstance(useInterceptors: boolean = true) {
    if (!CompeonReverseClient.instance) {
      CompeonReverseClient.instance = Axios.create({
        baseURL: env('WHITE_LABEL_API_URL'),
        headers: { 'Content-Type': 'application/vnd.api+json' },
      });
    }

    if (useInterceptors) {
      CompeonReverseClient.setupInterceptors();
    } else {
      CompeonReverseClient.instance.interceptors.request.clear();
      CompeonReverseClient.instance.interceptors.response.clear();
    }

    return CompeonReverseClient.instance;
  }

  private static setupInterceptors() {
    CompeonReverseClient.instance.interceptors.request.use(authedRequestInterceptor);
    CompeonReverseClient.instance.interceptors.response.use(
      noopResponseHandler,
      authedResponseExpiredInterceptor,
    );
  }
}

export class AuthClient {
  private static instance: AxiosInstance;

  public static getInstance(useInterceptors: boolean = true) {
    if (!AuthClient.instance) {
      AuthClient.instance = Axios.create({
        baseURL: env('AUTH_API_URL'),
        responseType: 'json',
      });
    }

    if (useInterceptors) {
      AuthClient.setupInterceptors();
    } else {
      AuthClient.instance.interceptors.request.clear();
      AuthClient.instance.interceptors.response.clear();
    }

    return AuthClient.instance;
  }

  private static setupInterceptors() {}
}

class CompeonOAuthClient {
  private static instance: AxiosInstance;

  public static getInstance(useInterceptors: boolean = true) {
    if (!CompeonOAuthClient.instance) {
      CompeonOAuthClient.instance = Axios.create({
        baseURL: env('COMPEON_OAUTH_API_URL'),
        responseType: 'json',
      });
    }

    if (useInterceptors) {
      CompeonOAuthClient.setupInterceptors();
    } else {
      CompeonOAuthClient.instance.interceptors.request.clear();
      CompeonOAuthClient.instance.interceptors.response.clear();
    }

    return CompeonOAuthClient.instance;
  }

  private static setupInterceptors() {}
}

export class CompeonClient {
  private static instance: AxiosInstance;

  public static getInstance(useInterceptors: boolean = true) {
    if (!CompeonClient.instance) {
      CompeonClient.instance = Axios.create({
        baseURL: env('COMPEON_API_URL'),
        responseType: 'json',
      });
    }

    if (useInterceptors) {
      CompeonClient.setupInterceptors();
    } else {
      CompeonClient.instance.interceptors.request.clear();
      CompeonClient.instance.interceptors.response.clear();
    }

    return CompeonClient.instance;
  }

  private static setupInterceptors() {
    CompeonClient.instance.interceptors.request.use(authedRequestInterceptor);
    CompeonClient.instance.interceptors.response.use(
      noopResponseHandler,
      authedResponseExpiredInterceptor,
    );
  }
}

class EcoBankingAxiosClientAuthed {
  private static instance: AxiosInstance;

  public static getInstance(useInterceptors: boolean = true) {
    if (!EcoBankingAxiosClientAuthed.instance) {
      EcoBankingAxiosClientAuthed.instance = Axios.create({
        baseURL: env('WHITE_LABEL_API_URL'),
        headers: {
          'Content-Type': 'application/vnd.api+json',
        },
      });
    }

    if (useInterceptors) {
      EcoBankingAxiosClientAuthed.setupInterceptors();
    } else {
      EcoBankingAxiosClientAuthed.instance.interceptors.request.clear();
      EcoBankingAxiosClientAuthed.instance.interceptors.response.clear();
    }

    return EcoBankingAxiosClientAuthed.instance;
  }

  private static setupInterceptors() {
    EcoBankingAxiosClientAuthed.instance.interceptors.request.use(authedRequestInterceptor);

    EcoBankingAxiosClientAuthed.instance.interceptors.response.use(
      noopResponseHandler,
      authedResponseExpiredInterceptor,
    );
  }
}

class EcoBankingAxiosClient {
  private static instance: AxiosInstance;

  public static getInstance(useInterceptors: boolean = true) {
    if (!EcoBankingAxiosClient.instance) {
      EcoBankingAxiosClient.instance = Axios.create({
        baseURL: env('WHITE_LABEL_API_URL'),
        headers: {
          'Content-Type': 'application/json',
        },
      });
    }
    if (useInterceptors) {
      EcoBankingAxiosClient.setupInterceptors();
    } else {
      EcoBankingAxiosClient.instance.interceptors.request.clear();
      EcoBankingAxiosClient.instance.interceptors.response.clear();
    }

    return EcoBankingAxiosClient.instance;
  }

  private static setupInterceptors() {
    EcoBankingAxiosClient.instance.interceptors.request
      .use
      // Your interceptor code here
      ();
    EcoBankingAxiosClient.instance.interceptors.response
      .use
      // Your interceptor code here
      ();
  }
}

export const MockApiClientInstance = MockApiClient.getInstance();
export const CompeonReverseClientInstance = CompeonReverseClient.getInstance();
export const AuthClientInstance = AuthClient.getInstance();
export const CompeonOAuthClientInstance = CompeonOAuthClient.getInstance();
export const CompeonClientInstance = CompeonClient.getInstance();
export const EcoBankingAxiosClientAuthedInstance = EcoBankingAxiosClientAuthed.getInstance();
export const EcoBankingAxiosClientInstance = EcoBankingAxiosClient.getInstance();
