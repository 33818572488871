import React, { useMemo } from 'react';

import { FieldInputProps, FieldMetaState } from 'react-final-form';

import FormRow from 'components/FormRow';
import FieldGroup from 'components/PortalFormFields/FieldGroup';
import Autocomplete from 'components/PortalFormFields/SelectField/Autocomplete/Autocomplete';
import Tag from 'components/Tag';
import { MultiSelectOption } from 'modules/Inquiry/Form/Steps/RequestDetails/BankSearch/MultiselectOption.model';
import TagListWrapper from 'modules/Inquiry/Form/Steps/RequestDetails/BankSearch/TagListWrapper';
import withFormField from 'utils/form/withFormField';

type OptionValue = MultiSelectOption['value'];

interface MultiSelectProps {
  // not recieving data in name so defined fieldName for it. // todo: needs to be checked throughly
  name: string;
  options: MultiSelectOption[];
  selectedOptions: MultiSelectOption[];
  placeholder: string;
  onInputChange: (value: OptionValue) => void;
  onSelect: (selection?: MultiSelectOption) => void;
  onRemove: (optionKey: OptionValue) => void;
  parseLabelForOption?: (option: MultiSelectOption) => string;
  onScrollEnd?: () => void;
  onFocus?: () => void;
  isLoading?: boolean;
  optional?: boolean;
  caption?: string;
  error?: string | null;
  testId: string;
}

/*
  IMPORTANT!!!
  MultiSelect field does only work if used objects have a key property. Additionally
  the object needs a label since that is used for the displayed tags
  Without the key property you run into an eternal rerender
  IMPORTANT!!!
*/

const MultiSelect = ({
  // same no data in name, thats why no validation working.
  name,
  caption,
  error,
  options,
  selectedOptions,
  placeholder,
  onFocus,
  onInputChange,
  onSelect,
  isLoading,
  onRemove,
  onScrollEnd,
  optional,
  testId,
  parseLabelForOption = ({ label }) => label,
}: MultiSelectProps) => {
  const mappedOptions = useMemo(
    () =>
      options.map((option) => ({
        ...option,
        checked: selectedOptions.findIndex(({ key }) => key === option.key) > -1,
        label: parseLabelForOption(option),
      })),
    [options, parseLabelForOption, selectedOptions],
  );

  const handleSelect = (selectedKey: string) => {
    onSelect(options.find((o) => o.key === selectedKey));
  };

  const handleRemove = (value: OptionValue) => () => {
    onRemove(value);
  };

  return (
    <FieldGroup label={caption} error={error} optional={optional}>
      <Autocomplete
        name={name}
        options={mappedOptions}
        placeholder={placeholder}
        onFocus={onFocus}
        onSelect={handleSelect}
        onChange={onInputChange}
        loading={isLoading}
        closeOnSelect={false}
        onScrollEnd={onScrollEnd}
        menuShowThreshold={0}
        disableClear
        testId={testId}
      />
      <FormRow>
        <TagListWrapper data-testid="selected-elements-tags">
          {selectedOptions.map(({ label, value, key }) => (
            <Tag key={key} onRemove={handleRemove(value)} label={label}>
              {label}
            </Tag>
          ))}
        </TagListWrapper>
      </FormRow>
    </FieldGroup>
  );
};

interface MultiSelectAdapterProps extends MultiSelectProps {
  fieldName: string;
  input: FieldInputProps<any>;
  meta: FieldMetaState<any>;
  onChange: (data: unknown) => void;
  errorMessage: string | null;
}

const MultiSelectAdapter = ({
  fieldName,
  input,
  onChange,
  meta,
  errorMessage,
  onSelect,
  onRemove,
  ...rest
}: MultiSelectAdapterProps) => {
  // data is not being deleted from formValues, Added delete functinality here

  const handleSelect = (option?: MultiSelectOption) => {
    if (!option) {
      return;
    }
    onSelect(option);
    const currentValue = input.value || [];
    const itemExistsIndex = currentValue.findIndex(
      (el: MultiSelectOption) => el.key === option?.key,
    );

    if (itemExistsIndex > -1) {
      // data is not being deleted from formValues, Added delete functinality here.
      currentValue.splice(itemExistsIndex, 1);
      onChange(currentValue);
    } else {
      onChange([...currentValue, option]);
    }
  };

  const handleRemove = (optionValue: OptionValue) => {
    onRemove(optionValue);
    const currentValue = input.value || [];
    const itemExistsIndex = currentValue.findIndex(
      (el: MultiSelectOption) => el.key === optionValue,
    );
    currentValue.splice(itemExistsIndex, 1);
    onChange(currentValue);
  };

  return (
    <MultiSelect error={errorMessage} onSelect={handleSelect} onRemove={handleRemove} {...rest} />
  );
};

export const MultiSelectWithField = withFormField(MultiSelectAdapter);
