import camelcaseKeys from 'camelcase-keys';
import _get from 'lodash/get';
import _values from 'lodash/values';
import moment from 'moment';

import {
  IIndicativeCondition,
  IIndicativeOffer,
  IInquiryDetails,
} from 'models/InquiryDetails/DefaultInquiryDetails.model';
import UsageTypes from 'models/types/UsageTypes.type';
import {
  ADP_NUMBER,
  BENEFICIARY_BIRTH_COUNTRY,
  BENEFICIARY_BIRTHDAY,
  BENEFICIARY_COMPANY_SHARE,
  BENEFICIARY_FIRST_NAME,
  BENEFICIARY_GENDER,
  BENEFICIARY_LAST_NAME,
  BENEFICIARY_OWNERS,
  BENEFICIARY_PRIVATE_ADDRESS,
  BENEFICIARY_PRIVATE_CITY,
  BENEFICIARY_PRIVATE_COUNTRY,
  BENEFICIARY_TAX_ID,
  BENEFICIARY_ZIP_CODE,
  BFS_SERVICE__PURCHASED_INVOICES_PAID_OUT_ON,
  BFS_USER_PROFILE_INDUSTRY,
  COMPANY_DETAILS_COMPANY_TAX_NUMBER,
  COMPANY_DETAILS_GENDER__MAN,
  COMPANY_DETAILS_GENDER__WOMAN,
  COMPANY_DETAILS_INSTITUTIONAL_NUMBERS,
  CONDITION_INSTALLMENT_AMOUNT,
  CONDITION_INTEREST_RATE,
  CONDITION_LAST_INSTALLMENT_AMOUNT,
  CORONA_EXPENDITURE_OF_EMPLOYEES,
  CORONA_FINANCING_OPTION,
  CORONA_LIQUIDITY_NEED,
  CORONA_NUMBER_OF_EMPLOYEES,
  CORONA_REVENUE_OF_2019,
  CORONA_TOTAL_ASSETS,
  CORONA_VIABILTIY,
  CORONA_VIABILTIY_2020,
  END_USER_EMAIL,
  END_USER_FIRST_NAME,
  END_USER_GENDER,
  END_USER_LAST_NAME,
  END_USER_PHONE_NUMBER,
  EXCLUDED_BANKS,
  EXTERNAL_REFERENCE_ID,
  FASTLANE_ELIGIBLE,
  FICTITIOUS_BENEFICIARY_OWNER,
  FUNDING_CLOSING_DATE,
  FURTHER_NEED,
  FURTHER_NEED_VALUES,
  GENDER_MAN,
  GUARANTY_OTHER_TYPE,
  INCOME_SURPLUS,
  INVESTMENT_LOAN_TERM_IN_YEARS,
  PARTNER_SPECIFIC_IBAN,
  PRE_FINANCING_PERIOD,
  PROJECT_FINANCING_CURRENT_USAGE_TYPE,
  PROJECT_FINANCING_FUTURE_USAGE_TYPE,
  PROJECT_FINANCING_MARKET_TRIGGER_CONDITION,
  REAL_ESTATE_BUILDING_SIZE,
  SALES_PERSON,
  SPECIAL_FEATURES,
  STRUCTURE_NUMBER,
  USER_PROFILE_ADDITIONAL_ASSOCIATION_COMPANY,
  USER_PROFILE_ANNUAL_NET_PROFIT_OR_LOSS_CLASS,
  USER_PROFILE_BALANCE_SHEET,
  USER_PROFILE_COMPANY_ALREADY_CUSTOMER,
  USER_PROFILE_COMPANY_CITY,
  USER_PROFILE_COMPANY_NAME,
  USER_PROFILE_COMPANY_STREET,
  USER_PROFILE_COMPANY_ZIP_CODE,
  USER_PROFILE_DETAILED_ANNUAL_TURNOVER,
  USER_PROFILE_EMPLOYEE_COUNT,
  USER_PROFILE_ENERGY_EFFICIENT,
  USER_PROFILE_FOUNDING_MONTH,
  USER_PROFILE_FOUNDING_YEAR,
  USER_PROFILE_INDUSTRY,
  USER_PROFILE_INNOVATIVE_COMPANY,
  USER_PROFILE_LEGAL_FORM,
  USER_PROFILE_LEGAL_FORM_DESCRIPTION,
  USER_PROFILE_NEGATIVE_EQUITY_CAPITAL,
  USER_PROFILE_PHONE_NUMBER,
  USER_PROFILE_TURNOVER_CLASS,
  VB_NUMBER,
  VB_ZIP_CODE,
  VM_NUMBER,
} from 'modules/Inquiry/Form/formFields';
import { requestDetailsKeys } from 'modules/Inquiry/inquiryFieldsTranslations/labelTranslator/requestDetailsLabels';
import {
  filterObjectWithKeys,
  formatDateAndNull,
  getCompany,
  getCustomer,
  getCustomerForInvitation,
  getEndCustomer,
  getIndicativeConditions,
  getInquiry,
  getKycCompanies,
  getLegalRepresentatives,
  getVideoIdentificationsDetail,
  isKycDateValid,
  mapBankOffers,
  mapCompany,
  mapAssociatedCompany,
  mapCompeonOffers,
  mapCustomer,
  mapCustomerData,
  mapTransparencyRegister,
  mapUserProfile,
  mapVideoIdentification,
  parseDate,
} from 'modules/Inquiry/mapInquiryFromApi/mapInquiryFromApi.helpers';
import { INQUIRY_SECTIONS } from 'modules/InquiryDetails/InquirySections.model';
import {
  ALREADY_FACTORING,
  DOWN_PAYMENT,
  NUMBER_OF_CUSTOMERS,
  NUMBER_OF_INVOICES,
  PAYMENT_TERM,
  PURPOSE_STEP,
  RECEIVABLES_ASSIGNED_ELSEWHERE,
  SHARE_OF_SALES_BUSINESS_CUSTOMERS,
  SHARE_OF_SALES_BUSINESS_CUSTOMERS_STEP,
  SHARE_OF_SALES_FOREIGN_CUSTOMERS,
  SHARE_OF_SALES_MAIN_CUSTOMERS,
  TRADE_CREDIT_INSURANCE,
  VOLUME_OF_SALES,
} from 'onlinefactoring/formFields';
import { filterObjectEmptyKeys, sortObjByKeyList } from 'utils/helpers';
import { monthFormat } from 'utils/valueFormats';

import { InquiryType } from '../Inquiry.type';
import {
  companyDetailsKeys,
  contractDetailsKeys,
  financingNeedKeys,
} from '../inquiryFieldsTranslations/useInquiryLabelTranslator';
import { InquiryMapType } from '../InquiryMapType';

const mapBankAdvisor = (normalizedResponse: any) => {
  const bankAdvisorId = _get(getInquiry(normalizedResponse), 'relationships.bank_advisor.data.id');
  const bankAdvisor = _get(normalizedResponse.portal_users, [bankAdvisorId]);

  if (!bankAdvisor) {
    return null;
  }

  return {
    id: bankAdvisor.id,
    firstName: bankAdvisor.attributes.first_name,
    lastName: bankAdvisor.attributes.last_name,
  };
};

const getSectionDataForKeys = (normalizedResponse: any, sectionKeys: string[]) => {
  const inquiry = getInquiry(normalizedResponse);
  const sectionData = filterObjectWithKeys(inquiry.attributes.details, sectionKeys);

  const sortedKeys = sectionKeys.filter((key) => Object.keys(sectionData).includes(key));
  return sortObjByKeyList(sectionData, sortedKeys);
};

const checkTypeOfUsage = (inquiry: any) => {
  const currentType = inquiry['current_type_of_usage'];
  const futureType = inquiry['future_type_of_usage'];

  type CurrentFutureType = 'future' | 'current';

  type ResType = {
    isButtonActive: boolean;
    displayMarketData: boolean;
    typesDefaults0: Array<CurrentFutureType>;
  };
  const res: ResType = {
    isButtonActive: false,
    displayMarketData: false,
    typesDefaults0: [],
  };
  if (currentType && futureType) {
    const isButtonActive =
      (futureType === UsageTypes.RESIDENTIAL && currentType === UsageTypes.RESIDENTIAL) ||
      (futureType === UsageTypes.RESIDENTIAL && currentType === UsageTypes.MIXED) ||
      (futureType === UsageTypes.MIXED && currentType === UsageTypes.RESIDENTIAL) ||
      (futureType === UsageTypes.MIXED && currentType === UsageTypes.MIXED);
    const isButtonActiveWithCurrentTypeDefaults0 =
      (futureType === UsageTypes.RESIDENTIAL && currentType === UsageTypes.COMMERCIAL) ||
      (futureType === UsageTypes.RESIDENTIAL && currentType === UsageTypes.NOT_PRESENT) ||
      (futureType === UsageTypes.MIXED && currentType === UsageTypes.COMMERCIAL) ||
      (futureType === UsageTypes.MIXED && currentType === UsageTypes.NOT_PRESENT);
    const isButtonActiveWithFutureTypeDefaults0 =
      (futureType === UsageTypes.COMMERCIAL && currentType === UsageTypes.RESIDENTIAL) ||
      (futureType === UsageTypes.COMMERCIAL && currentType === UsageTypes.MIXED);
    const isButtonInactiveWithFutureAndCurrentDefaults0 =
      (futureType === UsageTypes.COMMERCIAL && currentType === UsageTypes.COMMERCIAL) ||
      (futureType === UsageTypes.COMMERCIAL && currentType === UsageTypes.NOT_PRESENT);
    if (isButtonActive) {
      res.isButtonActive = true;
      res.displayMarketData = true;
    } else if (isButtonActiveWithCurrentTypeDefaults0) {
      res.isButtonActive = true;
      res.displayMarketData = true;
      res.typesDefaults0 = ['current'];
    } else if (isButtonActiveWithFutureTypeDefaults0) {
      res.isButtonActive = true;
      res.displayMarketData = true;
      res.typesDefaults0 = ['future'];
    } else if (isButtonInactiveWithFutureAndCurrentDefaults0) {
      res.isButtonActive = false;
      res.displayMarketData = true;
      res.typesDefaults0 = ['current', 'future'];
    }
  }
  return res;
};

const mapFinancingNeed = (normalizedResponse: any, type = InquiryType.default) => {
  const inquiry = getInquiry(normalizedResponse);
  let selectedGuarantyOthers = _get(inquiry, 'attributes.details.guaranty-other-type') ?? null;

  if (type === InquiryType.bfsService) {
    inquiry.attributes.details[PAYMENT_TERM] = _get(
      inquiry,
      'attributes.details.standard-payment-term',
    );
    inquiry.attributes.details[SPECIAL_FEATURES] = _get(
      inquiry,
      'attributes.details.special-features',
    );
  }

  const typeReturn: InquiryMapType<string[]> = {
    default: {
      ...getSectionDataForKeys(normalizedResponse, financingNeedKeys(type)),
    },
    [InquiryType.bfs]: {
      ...getSectionDataForKeys(normalizedResponse, financingNeedKeys(type)),
      [GUARANTY_OTHER_TYPE]: !selectedGuarantyOthers ? null : selectedGuarantyOthers,
      [REAL_ESTATE_BUILDING_SIZE]: _get(
        inquiry,
        'attributes.details.real-estate-total-living-and-usable-space',
      ),
    },
    [InquiryType.profiMittweida]: {
      ...getSectionDataForKeys(normalizedResponse, financingNeedKeys(type)),
      [PROJECT_FINANCING_FUTURE_USAGE_TYPE]: inquiry.attributes['future_type_of_usage'],
      [PROJECT_FINANCING_CURRENT_USAGE_TYPE]: inquiry.attributes['current_type_of_usage'],
    },
    [InquiryType.profiRLL]: {
      ...getSectionDataForKeys(normalizedResponse, financingNeedKeys(type)),
      [PROJECT_FINANCING_FUTURE_USAGE_TYPE]: inquiry.attributes['future_type_of_usage'],
      [PROJECT_FINANCING_CURRENT_USAGE_TYPE]: inquiry.attributes['current_type_of_usage'],
      [PROJECT_FINANCING_MARKET_TRIGGER_CONDITION]: checkTypeOfUsage(inquiry.attributes),
    },
  };
  return typeReturn[type] || typeReturn.default;
};

const mapContractDetails = (normalizedResponse: any) => {
  const inquiry = getInquiry(normalizedResponse);
  const salesPerson = _get(inquiry, `attributes.details.${SALES_PERSON}`);

  const result = { ...getSectionDataForKeys(normalizedResponse, contractDetailsKeys) };
  if (salesPerson) {
    result[SALES_PERSON] = `${salesPerson['first-name']} ${salesPerson['last-name']}`;
  }
  return result;
};

const mapRequestDetails = (normalizedResponse: any) => {
  const inquiry = getInquiry(normalizedResponse);
  const bankCodes = _get(inquiry, 'attributes.details.excluded-bank-codes');

  const fundingClosingDate = moment(
    _get(inquiry, 'attributes.details.funding-closing-date') ?? '',
  ).format(monthFormat);
  return {
    ...getSectionDataForKeys(normalizedResponse, requestDetailsKeys),
    [EXCLUDED_BANKS]: !bankCodes
      ? null
      : Array.isArray(bankCodes) && bankCodes.map((code) => code.name),
    [INVESTMENT_LOAN_TERM_IN_YEARS]: _get(inquiry, 'attributes.details.loan-term-in-years'),
    [FUNDING_CLOSING_DATE]: fundingClosingDate === 'Invalid date' ? null : fundingClosingDate,
    [BFS_SERVICE__PURCHASED_INVOICES_PAID_OUT_ON]: _get(
      inquiry,
      'attributes.details.purchased-invoices-paid-out-time',
    ),
    [PRE_FINANCING_PERIOD]: _get(inquiry, 'attributes.details.pre-financing-period'),
    [FURTHER_NEED]: _get(inquiry, 'attributes.details.further-need'),
    [FURTHER_NEED_VALUES]: _get(inquiry, 'attributes.details.further-need-values'),
  };
};

const mapCompanyDetails = (normalizedResponse: any, type = InquiryType.default) => {
  const customer = getCustomer(normalizedResponse);
  const company = getCompany(normalizedResponse);
  const inquiry = getInquiry(normalizedResponse);
  let result = {
    [USER_PROFILE_COMPANY_NAME]: _get(company, 'attributes.name'),
    [USER_PROFILE_COMPANY_STREET]: _get(company, 'attributes.street'),
    [USER_PROFILE_COMPANY_ZIP_CODE]: _get(company, 'attributes.zip_code'),
    [USER_PROFILE_COMPANY_CITY]: _get(company, 'attributes.city'),
    [USER_PROFILE_PHONE_NUMBER]: _get(customer, 'attributes.phone_number'),
    [USER_PROFILE_DETAILED_ANNUAL_TURNOVER]: _get(company, 'attributes.detailed_annual_turnover'),
    [USER_PROFILE_BALANCE_SHEET]: _get(company, 'attributes.detailed_balance_sheet'),
    [USER_PROFILE_EMPLOYEE_COUNT]: _get(company, 'attributes.count_of_employees'),
    [USER_PROFILE_LEGAL_FORM]: _get(company, 'attributes.legal_form'),
    [USER_PROFILE_LEGAL_FORM_DESCRIPTION]: _get(company, 'attributes.legal_form_description'),
    [USER_PROFILE_INDUSTRY]:
      _get(company, 'attributes.industry').length > 0 ? _get(company, 'attributes.industry') : null,
    [BFS_USER_PROFILE_INDUSTRY]:
      _get(company, 'attributes.industry').length > 0 ? _get(company, 'attributes.industry') : null,
    [USER_PROFILE_TURNOVER_CLASS]: _get(company, 'attributes.turnover_class'),
    [USER_PROFILE_FOUNDING_YEAR]: _get(company, 'attributes.founding_year'),
    [USER_PROFILE_COMPANY_ALREADY_CUSTOMER]: _get(company, 'attributes.already_customer'),
    [USER_PROFILE_ADDITIONAL_ASSOCIATION_COMPANY]: _get(
      inquiry,
      'attributes.details.additional-association-company',
    ),
    [USER_PROFILE_ANNUAL_NET_PROFIT_OR_LOSS_CLASS]: _get(
      inquiry,
      'attributes.details.annual-net-profit-or-loss-class',
    ),
    [USER_PROFILE_NEGATIVE_EQUITY_CAPITAL]: _get(
      inquiry,
      'attributes.details.negative-equity-capital',
    ),
    [USER_PROFILE_INNOVATIVE_COMPANY]: _get(inquiry, 'attributes.details.innovative-company'),
    [USER_PROFILE_ENERGY_EFFICIENT]: _get(inquiry, 'attributes.details.energy-efficient'),
    [INCOME_SURPLUS]: _get(company, 'attributes.revenue_surplus_account'),
    [PARTNER_SPECIFIC_IBAN]:
      _get(inquiry, 'attributes.details.iban') || _get(company, 'attributes.account_iban'),
    [USER_PROFILE_FOUNDING_MONTH]: _get(company, 'attributes.founding_month', null),
    [COMPANY_DETAILS_COMPANY_TAX_NUMBER]: _get(
      company,
      'attributes.additional_details.company_tax_number',
    ),
    [COMPANY_DETAILS_INSTITUTIONAL_NUMBERS]: _get(
      company,
      'attributes.additional_details.institution_identifier',
    ),
  };
  const alreadyCustomer = _get(company, 'attributes.already_customer');

  const typeReturn: InquiryMapType<string[]> = {
    default: {
      ...getSectionDataForKeys(normalizedResponse, companyDetailsKeys),
      ...result,
    },
    [InquiryType.bfs]: {
      ...getSectionDataForKeys(normalizedResponse, companyDetailsKeys),
      ...result,
      [PARTNER_SPECIFIC_IBAN]: alreadyCustomer ? _get(company, 'attributes.account_iban') : null,
    },
  };
  return typeReturn[type] || typeReturn.default;
};

const mapAdditionalFields = (normalizedResponse: any) => {
  const customer = getCustomer(normalizedResponse);
  const inquiry = getInquiry(normalizedResponse);

  return {
    [VB_ZIP_CODE]: _get(customer, 'attributes.zip_code'),
    [VB_NUMBER]: _get(customer, 'attributes.vb_number'),
    [VM_NUMBER]: _get(inquiry, 'attributes.details.vm-number'),
    [STRUCTURE_NUMBER]: _get(customer, 'attributes.structure_number'),
    [ADP_NUMBER]: _get(customer, 'attributes.adp_root_number'),
    [SHARE_OF_SALES_BUSINESS_CUSTOMERS]: _get(
      inquiry,
      'attributes.details.factoring-business-customers-share',
    ),
    [SHARE_OF_SALES_BUSINESS_CUSTOMERS_STEP]: _get(
      inquiry,
      'attributes.details.share-of-sales-business-customers',
    ),
    [SHARE_OF_SALES_FOREIGN_CUSTOMERS]: _get(
      inquiry,
      'attributes.details.factoring-foreign-customers-share',
    ),
    [SHARE_OF_SALES_MAIN_CUSTOMERS]: _get(
      inquiry,
      'attributes.details.factoring-main-customers-share',
    ),
    [NUMBER_OF_CUSTOMERS]: _get(inquiry, 'attributes.details.factoring-active-customers'),
    [ALREADY_FACTORING]: _get(inquiry, 'attributes.details.factoring-already-in-use'),
    [DOWN_PAYMENT]: _get(
      inquiry,
      'attributes.details.factoring-issue-payment-or-progress-invoices',
    ),
    [NUMBER_OF_INVOICES]: _get(inquiry, 'attributes.details.factoring-number-of-invoices'),
    [RECEIVABLES_ASSIGNED_ELSEWHERE]: _get(
      inquiry,
      'attributes.details.factoring-receivables-assigned',
    ),
    [VOLUME_OF_SALES]: _get(inquiry, 'attributes.details.factoring-sales-gross'),
    [PURPOSE_STEP]: _get(inquiry, 'attributes.details.factoring-sales-gross'),
    [PAYMENT_TERM]: _get(inquiry, 'attributes.details.factoring-standard-payment-term'),
    [TRADE_CREDIT_INSURANCE]: _get(
      inquiry,
      'attributes.details.factoring-trade-credit-insurance-taken',
    ),
    [FASTLANE_ELIGIBLE]: _get(inquiry, 'attributes.fastlane_eligible'),
  };
};

const mapEndUserDetails = (normalizedResponse: any) => {
  const { attributes } = getEndCustomer(normalizedResponse) || {};

  return {
    [END_USER_GENDER]:
      _get(attributes, 'salutation') === GENDER_MAN
        ? COMPANY_DETAILS_GENDER__MAN
        : COMPANY_DETAILS_GENDER__WOMAN,
    [END_USER_FIRST_NAME]: _get(attributes, 'first_name'),
    [END_USER_LAST_NAME]: _get(attributes, 'last_name'),
    [END_USER_EMAIL]: _get(attributes, 'email'),
    [END_USER_PHONE_NUMBER]: _get(attributes, 'phone_number') || undefined,
  };
};

const mapCoronaDetails = (normalizedResponse: any) => {
  const inquiry = getInquiry(normalizedResponse);
  const coronaPackage = _get(inquiry, 'attributes.details.corona-package-attributes');
  if (!coronaPackage) {
    return undefined;
  }
  return {
    [CORONA_REVENUE_OF_2019]: coronaPackage['revenue-2019'],
    [CORONA_LIQUIDITY_NEED]: coronaPackage['liquidity-need'],
    [CORONA_VIABILTIY]: coronaPackage['viability-2019'],
    [CORONA_VIABILTIY_2020]: coronaPackage['viability-2020'],
    [CORONA_FINANCING_OPTION]: inquiry.attributes.kfw_program,
    [CORONA_NUMBER_OF_EMPLOYEES]: coronaPackage['count-of-employees'],
    [CORONA_EXPENDITURE_OF_EMPLOYEES]: coronaPackage['expenditure-for-employees'],
    [CORONA_TOTAL_ASSETS]: coronaPackage['total-assets-greater-than-43-mil'],
  };
};

/**
 * Transforms a normalized response to a map of beneficiary owners. There is some bad logic implemented in the BE for the inquiryType BFS Service where the BOs are returned inside the attributes details and not in the "included" relationship which would be the correct way to do it. Thus, we have to different ways of mapping the BOs. The first one is for BFS Service and the other one should work for all other inquiryTypes.
 * You can find more information in this thread: https://finstreet.slack.com/archives/C04NA0CM1J8/p1692181213714589?thread_ts=1692180138.076029&cid=C04NA0CM1J8
 * @param {any} normalizedResponse - The normalized response to be transformed.
 * @returns {Array} - The array of beneficiary owners.
 */

const mapBeneficiaryOwners = (normalizedResponse: any) => {
  const inquiry = getInquiry(normalizedResponse);
  const beneficiaryOwners = inquiry.attributes?.details?.[BENEFICIARY_OWNERS] || [];
  const defaultBeneficiaryOwners = _values(normalizedResponse.beneficiary_owners) || [];

  if (beneficiaryOwners.length > 0) {
    // BFS Service solution
    return beneficiaryOwners.map(
      (beneficiary: {
        address: string;
        'birth-date': string;
        'birth-place': string;
        city: string;
        'company-share': string;
        country: string;
        'first-name': string;
        'last-name': string;
        salutation: string;
        'zip-code': string;
        'tax-id'?: string;
      }) => ({
        [BENEFICIARY_GENDER]: beneficiary.salutation,
        [BENEFICIARY_FIRST_NAME]: beneficiary['first-name'],
        [BENEFICIARY_LAST_NAME]: beneficiary['last-name'],
        [BENEFICIARY_COMPANY_SHARE]: beneficiary['company-share'],
        [BENEFICIARY_TAX_ID]: beneficiary['tax-id'],
        [BENEFICIARY_PRIVATE_ADDRESS]: beneficiary['address'],
        [BENEFICIARY_ZIP_CODE]: beneficiary['zip-code'],
        [BENEFICIARY_PRIVATE_CITY]: beneficiary['city'],
        [BENEFICIARY_PRIVATE_COUNTRY]: beneficiary['country'],
        [BENEFICIARY_BIRTH_COUNTRY]: beneficiary['birth-place'],
        [BENEFICIARY_BIRTHDAY]: formatDateAndNull(beneficiary['birth-date']),
      }),
    );
  } else if (defaultBeneficiaryOwners.length > 0) {
    // default solution that should work for all inquiryTypes
    return defaultBeneficiaryOwners.map(({ attributes }) => {
      return {
        [BENEFICIARY_FIRST_NAME]: attributes.first_name,
        [BENEFICIARY_LAST_NAME]: attributes.last_name,
        [BENEFICIARY_GENDER]: attributes.salutation,
        [BENEFICIARY_BIRTHDAY]: attributes.birth_date,
        [BENEFICIARY_COMPANY_SHARE]: attributes.company_share,
        [BENEFICIARY_BIRTH_COUNTRY]: attributes.birth_place,
        [BENEFICIARY_TAX_ID]: attributes.tax_id,
        [BENEFICIARY_PRIVATE_ADDRESS]: attributes.address,
        [BENEFICIARY_PRIVATE_CITY]: attributes.city,
        [BENEFICIARY_PRIVATE_COUNTRY]: attributes.country,
        [BENEFICIARY_ZIP_CODE]: attributes.zip_code,
        [FICTITIOUS_BENEFICIARY_OWNER]: attributes.additional_info?.wb_fictitious,
      };
    });
  }

  return [];
};

const mapInquiryFromApi = (normalizedResponse: any): IInquiryDetails => {
  const inquiry = getInquiry(normalizedResponse);
  const company = getCompany(normalizedResponse);
  const customer = getCustomerForInvitation(normalizedResponse);

  const { id, attributes } = inquiry;

  const indicativeOfferDataExists =
    inquiry.relationships.indicative_condition &&
    inquiry.relationships.indicative_condition.data !== null;

  const indicativeOffer: Partial<IIndicativeOffer> = indicativeOfferDataExists
    ? getIndicativeConditions(normalizedResponse, attributes.form_type)
    : {};

  const indicativeCondition: IIndicativeCondition | undefined = indicativeOfferDataExists
    ? undefined
    : {
        [CONDITION_INTEREST_RATE]: attributes.details['interest-rate'],
        [CONDITION_LAST_INSTALLMENT_AMOUNT]: attributes.details['last-instalment-amount'],
        [CONDITION_INSTALLMENT_AMOUNT]: attributes.details['instalment-amount'],
      };

  const indicativeConditionExists =
    indicativeCondition && Object.values(indicativeCondition).every((k) => k);

  return {
    inquiryId: id,
    inquiryTypeSpecificDetails: camelcaseKeys(attributes.details),
    formType: attributes.form_type,
    videoIdentUrl: attributes.video_ident_url,
    videoIdentStatus: attributes.video_ident_status,
    status: attributes.status,
    substatus: attributes.substatus,
    createdAt: parseDate(inquiry, ['created_at']),
    sentAt: parseDate(inquiry, ['sent_at']),
    welcomeCallPerformedDate: parseDate(inquiry, ['welcome_call_performed_at']),
    wasForwardToCompeon: Boolean(inquiry.attributes.sent_to_compeon_at),
    wasForwardedToInsurance: Boolean(inquiry.attributes.insurance_offer_requested_at),
    compeonStatus: inquiry.attributes.compeon_status,
    fastlaneCancelledDate: parseDate(inquiry, ['fastlane_cancelled_at']),
    archivingReason: attributes.details['reason-for-archiving'],
    legalRepresentativesRequireManualMerging:
      attributes['legal_representatives_require_manual_merging'],
    customerLegalRepresentativeId: attributes['customer_legal_representative_id'],
    legalRepresentativesAccepted: attributes.details['legal-representatives-accepted'],
    userLegalRepresentation: attributes.details['user-legal-representation'],
    customersSoleSignatureAuthorized: attributes['customers_sole_signature_authorized'],

    [EXTERNAL_REFERENCE_ID]: attributes.external_reference,
    [INQUIRY_SECTIONS.USER]: mapUserProfile(normalizedResponse, attributes.form_type),

    collaterals: attributes.collaterals || [],
    [INQUIRY_SECTIONS.CONTRACT_DETAILS]: mapContractDetails(normalizedResponse),
    [INQUIRY_SECTIONS.CUSTOMER_DATA]: mapCustomerData(normalizedResponse),
    [INQUIRY_SECTIONS.FINANCING]: mapFinancingNeed(normalizedResponse, attributes.form_type),
    [INQUIRY_SECTIONS.DETAILS]: mapRequestDetails(normalizedResponse),
    [INQUIRY_SECTIONS.COMPANY]: mapCompanyDetails(normalizedResponse, attributes.form_type),
    [INQUIRY_SECTIONS.END_USER]: mapEndUserDetails(normalizedResponse),
    [INQUIRY_SECTIONS.ADDITIONAL_FIELDS]: mapAdditionalFields(normalizedResponse),
    [INQUIRY_SECTIONS.CORONA_STEP]: filterObjectEmptyKeys(mapCoronaDetails(normalizedResponse)),
    bankAdvisor: mapBankAdvisor(normalizedResponse),
    company: mapCompany(normalizedResponse),
    associatedCompany: mapAssociatedCompany(normalizedResponse),
    bankOffers: mapBankOffers(normalizedResponse),
    compeonOffers: mapCompeonOffers(normalizedResponse),
    customer: mapCustomer(normalizedResponse),
    lane: _get(inquiry, 'attributes.specialized_lane') || _get(inquiry, 'attributes.details.lane'),
    videoIdentification: mapVideoIdentification(normalizedResponse),
    videoIdentificationsDetail: getVideoIdentificationsDetail(normalizedResponse),
    dashboardActions: {
      indicativeConditionCompleted: indicativeConditionExists || indicativeOfferDataExists,
      indicativeOffer: indicativeOffer,
      indicativeCondition: indicativeConditionExists ? indicativeCondition : {},
      transparencyRegister: mapTransparencyRegister(normalizedResponse),
      kycTriggered: isKycDateValid(_get(company, 'attributes.kyc_reports_requested_at')),
      customerInvitationAccepted: _get(customer, 'attributes.invitation_accepted'),
      customerInvitationSend: _get(customer, 'attributes.invitation_sent'),
      marketDataSent: attributes.details['market-data-computation-completion'],
      assignedRealEstateExpert: attributes.details['is-assigned-real-estate-expert'],
      isQuickCheckStarted: attributes['is_quick_check_started'],
      isKycProcessCompleted: attributes.details['kyc-process-completed'],
    },
    allowDigitalProcessing: inquiry.attributes.allow_digital_processing,
    externalId: attributes.external_id,
    legalRepresentatives: inquiry.relationships.legal_representatives?.data
      ? getLegalRepresentatives(normalizedResponse)
      : [],
    allRequiredFilesUploaded: inquiry.attributes.all_required_files_uploaded,
    kycCompanies: getKycCompanies(normalizedResponse),
    completedSignsCount: attributes.completed_signs_count,
    finalSignedContract: attributes.final_signed_contract,
    customerIdentificationType: attributes.customer_identification_type,
    fesStatus: attributes.fes_status,
    fesUrl: attributes.fes_url,
    bfssLane: _get(inquiry, 'attributes.details.lane'),
    beneficiaryOwners: mapBeneficiaryOwners(normalizedResponse),
    fileArchivingTriggeredAt: parseDate(inquiry, ['details', 'file-archiving-triggered-at']),
    expirationInDays: inquiry.attributes?.expiration_in_days,
    beneficialOwner: inquiry.attributes?.details?.['beneficial-owner'],
  };
};

export default mapInquiryFromApi;
