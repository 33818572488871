import { IBankOffer } from 'models/BankOffer.model';
import { ICompeonOffer } from 'models/CompeonOffer.model';
import { IDefaultFile, IInternalFile } from 'models/File.model';
import { IFileRequest } from 'models/FileRequest.model';
import { CompeonStatus } from 'models/types/CompeonStatus.type';
import { ITransparencyRegister } from 'models/types/TransparencyRegister.types';
import {
  ACCOUNT_OWNER,
  ADP_NUMBER,
  CAR_DEALER_NAME,
  COMPANY_DETAILS,
  COMPANY_DETAILS_COMPANY,
  COMPANY_DETAILS_EMAIL,
  COMPANY_DETAILS_FIRST_NAME,
  COMPANY_DETAILS_GENDER,
  COMPANY_DETAILS_GENDER__MAN,
  COMPANY_DETAILS_GENDER__WOMAN,
  COMPANY_DETAILS_LAST_NAME,
  CONDITION_INSTALLMENT_AMOUNT,
  CONDITION_INTEREST_RATE,
  CONDITION_LAST_INSTALLMENT_AMOUNT,
  CONTACT_PERSON_DETAILS,
  CUSTOMER_BIRTH_DATE,
  CUSTOMER_COMPANY_ADDRESS,
  CUSTOMER_COMPANY_NAME,
  CUSTOMER_EMAIL,
  CUSTOMER_FIRST_NAME,
  CUSTOMER_LAST_NAME,
  CUSTOMER_PHONE_NUMBER,
  CUSTOMER_SALUTATION,
  EXCLUDED_BANKS,
  FAVORED_DECISION_DATE,
  FINANCING_AMOUNT,
  FINANCING_NEED,
  IBAN,
  LEASING_DURATION,
  LEASING_INSTALLMENT,
  LOAN_TERM,
  LOAN_TYPE,
  LOAN_TYPE__AMORTISABLE,
  LOAN_TYPE__ANNUITY,
  LOAN_TYPE__PAYABLE_AT_TERM,
  OFFER_FACTORING_FEE,
  OFFER_FACTORING_LINE,
  OFFER_INSTALLMENT_RATE,
  OFFER_MONTHLY_INSTALLMENT,
  OFFER_PAYOUT_RATIO,
  PRODUCT_KIND,
  PRODUCT_KIND__FACTORING,
  PRODUCT_KIND__HIRE_PURCHASE,
  PRODUCT_KIND__INVESTMENT_LOAN,
  PRODUCT_KIND__LEASING,
  PRODUCT_KIND__LOAN,
  PRODUCT_KIND__MEZZANINE,
  PRODUCT_KIND__OTHER,
  PRODUCT_KIND__OVERDRAFT,
  PRODUCT_KIND__PROJECT_FINANCING,
  PRODUCT_KIND__PROPERTY_LOAN,
  PRODUCT_KIND__SURETY,
  PROJECT_FINANCING__BALKONY,
  PROJECT_FINANCING__BUILDING_YEAR,
  PROJECT_FINANCING__CELLAR,
  PROJECT_FINANCING__FURNISHING_QUALITY,
  PROJECT_FINANCING__GUEST_BATHROOM,
  PROJECT_FINANCING__HAS_ENERGY_CERTIFICATE,
  PROJECT_FINANCING__HAS_LIST_OF_BUILDING_ENCUMBRANCES,
  PROJECT_FINANCING__LIFT,
  PROJECT_FINANCING__MODERNIZATION_YEAR,
  PROJECT_FINANCING__NUMBER_OF_GARAGES,
  PROJECT_FINANCING__NUMBER_OF_PARKING_LOTS,
  PROJECT_FINANCING__NUMBER_OF_UNITS,
  PROJECT_FINANCING__OBJECT_USAGE,
  PROJECT_FINANCING__QUALITY_CONDITION,
  PROJECT_FINANCING_INVESTMENT_AMOUNT,
  PROJECT_FINANCING_LIST_OF_CONTAMINATED_SITES_EXIST,
  PROJECT_FINANCING_LIST_OF_RENTERS,
  PROJECT_FINANCING_LOT_SIZE,
  PROJECT_FINANCING_MEZZANINE,
  PROJECT_FINANCING_OBJECT_ADDRESS,
  PROJECT_FINANCING_OBJECT_CITY,
  PROJECT_FINANCING_OBJECT_DESCRIPTION,
  PROJECT_FINANCING_OBJECT_ZIP_CODE,
  PROJECT_FINANCING_OWN_FUNDS,
  PROJECT_FINANCING_OWN_WORK,
  PROJECT_FINANCING_PERCENTAGE_OF_RENT,
  PROJECT_FINANCING_RENOVATION_PLANNED,
  PROJECT_FINANCING_RENTAL_CONTRACTS,
  PROJECT_FINANCING_ROLE,
  PROJECT_FINANCING_SUBORDINATED_CAPITAL,
  PROJECT_FINANCING_TYPE_OF_USAGE,
  PROJECT_FINANCING_USAGE_KIND_TYPE,
  PROJECT_FINANCING_USAGE_SPACE,
  PURPOSE_KIND,
  REQUEST_DETAILS,
  SALES_PERSON,
  STRUCTURE_NUMBER,
  USER_PROFILE,
  USER_PROFILE_BIRTHDATE,
  USER_PROFILE_PHONE_NUMBER,
  VB_NUMBER,
  VB_ZIP_CODE,
  VEHICLE_DRIVE_TYPE,
  VEHICLE_KIND,
  VEHICLE_MANUFACTURER,
  VEHICLE_MODEL,
  VM_NUMBER,
  YEARLY_MILEAGE,
} from 'modules/Inquiry/Form/formFields';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { InquiryLane } from 'modules/Inquiry/InquiryLane';
import {
  INQUIRY_STATUSES,
  INQUIRY_SUBSTATUSES,
  MMV_INQUIRY_STATUSES,
} from 'modules/Inquiry/inquiryStatuses';
import { INQUIRY_SECTIONS } from 'modules/InquiryDetails/InquirySections.model';
import { VOLUME_OF_SALES } from 'onlinefactoring/formFields';
import { compeonExternalStateStepKeys } from 'pages/customerPortal/InquiryDetails/Dashboard/CompeonInquiryState/types';
import {
  KycCompany,
  LegalRepresentative,
} from 'pages/operationPortal/InquiryDetails/Dashboard/LegalRepresentativeCorrection/types';

import { IBeneficiaryOwner } from './../types/BeneficiaryOwner.type';

export interface InquiryDetailsCustomer {
  id?: string;
  existsInCompeon: boolean;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email?: string;
  gender?: string;
  vbNumber?: string;
  zipCode?: string;
}

export interface IUserProfile {
  [COMPANY_DETAILS_GENDER]:
    | typeof COMPANY_DETAILS_GENDER__MAN
    | typeof COMPANY_DETAILS_GENDER__WOMAN;
  [COMPANY_DETAILS_FIRST_NAME]: string;
  [COMPANY_DETAILS_LAST_NAME]: string;
  [COMPANY_DETAILS_EMAIL]: string;
  [COMPANY_DETAILS_COMPANY]: string;
  [USER_PROFILE_PHONE_NUMBER]: string;
  [CUSTOMER_BIRTH_DATE]?: string;
  [USER_PROFILE_BIRTHDATE]?: string;
}

export interface IContractDetailsFields {
  [SALES_PERSON]: string;
  [CAR_DEALER_NAME]: string;
  [VEHICLE_MANUFACTURER]: string;
  [VEHICLE_KIND]: string;
  [LEASING_DURATION]: string;
  [LEASING_INSTALLMENT]: string;
  [YEARLY_MILEAGE]: string;
  [ACCOUNT_OWNER]: string;
  [IBAN]: string;
  [VEHICLE_MODEL]: string;
  [VEHICLE_DRIVE_TYPE]: string;
}

export interface ICustomerDataFields {
  [CUSTOMER_COMPANY_NAME]: string;
  [CUSTOMER_COMPANY_ADDRESS]: string;
  [CUSTOMER_SALUTATION]: string;
  [CUSTOMER_FIRST_NAME]: string;
  [CUSTOMER_LAST_NAME]: string;
  [CUSTOMER_EMAIL]: string;
  [CUSTOMER_BIRTH_DATE]: string;
  [CUSTOMER_PHONE_NUMBER]: string;
}

interface IInquiryDetailsFields {
  [EXCLUDED_BANKS]: boolean;
  [FAVORED_DECISION_DATE]: string;
  [LOAN_TYPE]:
    | typeof LOAN_TYPE__AMORTISABLE
    | typeof LOAN_TYPE__ANNUITY
    | typeof LOAN_TYPE__PAYABLE_AT_TERM;
  [LOAN_TERM]: number;
  [PRODUCT_KIND]:
    | typeof PRODUCT_KIND__LOAN
    | typeof PRODUCT_KIND__PROPERTY_LOAN
    | typeof PRODUCT_KIND__LEASING
    | typeof PRODUCT_KIND__OVERDRAFT
    | typeof PRODUCT_KIND__FACTORING
    | typeof PRODUCT_KIND__OTHER
    | typeof PRODUCT_KIND__MEZZANINE
    | typeof PRODUCT_KIND__PROJECT_FINANCING
    | typeof PRODUCT_KIND__INVESTMENT_LOAN
    | typeof PRODUCT_KIND__HIRE_PURCHASE
    | typeof PRODUCT_KIND__SURETY;
}

interface IAdditionalFields {
  [ADP_NUMBER]: string;
  [STRUCTURE_NUMBER]: string;
  [VB_NUMBER]: string;
  [VM_NUMBER]: string;
  [VB_ZIP_CODE]: string;
  [VOLUME_OF_SALES]: number;
}

interface ICompany {
  id: string;
  name: string;
  compeonId: string;
  street?: string;
  zip_code?: string;
  city?: string;
}

interface IAssociatedCompany {
  agreementDate?: string;
  annualRevenueForecast?: string;
  contactPerson?: string;
  grossTotalPurchases?: string;
  name?: string;
}

interface IBankAdvisor {
  id: string;
  firstName: string;
  lastName: string;
}

export enum VideoIdentStatus {
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
  WAITING = 'WAITING',
}

export enum FESStatus {
  NOT_COMPLETED = 'NOT_COMPLETED',
}

export enum CustomerIdentificationType {
  QES = 'QES',
  FES = 'FES',
}

export interface IInquiryDetailsBase {
  inquiryId: string;
  formType: InquiryType;
  substatus: INQUIRY_SUBSTATUSES;
  status?: INQUIRY_STATUSES | MMV_INQUIRY_STATUSES;

  createdAt: Date;
  sentAt: Date;
  customer: InquiryDetailsCustomer;

  dashboardActions?: IDashboardAction; // satisfy the interface for inquiryDetails
  legalRepresentatives?: Omit<LegalRepresentative, 'isModified' | 'isComplete' | 'isCustomer'>[];
  kycCompanies?: Omit<KycCompany, 'isModified' | 'isComplete' | 'isCustomer'>[];
}

export interface IVideoIdentificationDetails {
  id: string;
  signedContractUrl: string;
  success: boolean;
}

export interface IInquiryDetails extends IInquiryDetailsBase {
  videoIdentUrl?: string;
  videoIdentStatus?: VideoIdentStatus;

  welcomeCallPerformedDate: Date;
  wasForwardToCompeon: boolean;
  wasForwardedToInsurance: boolean;
  compeonStatus: CompeonStatus;
  fastlaneCancelledDate: Date;
  archivingReason: string;
  collaterals: any[];
  bankAdvisor: IBankAdvisor | null;
  company: ICompany;
  associatedCompany: IAssociatedCompany;
  bankOffers: IBankOffer[];
  compeonOffers: ICompeonOffer[];
  'external-reference': string;
  externalId: string;

  /*
  These are all the details that we receive from the backend. The backend saves all inquiry fields in a JSON object
  in the database and just returns it to us. We don't know the structure of this object, so we just use any here.
  We should add robust type checking here in the future.
   */
  inquiryTypeSpecificDetails: any;
  [INQUIRY_SECTIONS.USER]: IUserProfile;
  [INQUIRY_SECTIONS.FINANCING]: any;
  [INQUIRY_SECTIONS.DETAILS]: IInquiryDetailsFields;
  [INQUIRY_SECTIONS.COMPANY]: any;
  [INQUIRY_SECTIONS.END_USER]: any;
  [INQUIRY_SECTIONS.CORONA_STEP]: any;
  [INQUIRY_SECTIONS.CONTRACT_DETAILS]: IContractDetailsFields;
  [INQUIRY_SECTIONS.CUSTOMER_DATA]: ICustomerDataFields;
  [INQUIRY_SECTIONS.ADDITIONAL_FIELDS]: IAdditionalFields;
  lane: InquiryLane;
  summaryDetails?: any[];
  subjectSums?: object;
  videoIdentification?: IInternalFile;
  videoIdentificationsDetail?: IVideoIdentificationDetails[];
  allowDigitalProcessing?: boolean;
  legalRepresentativesRequireManualMerging: boolean;
  customerLegalRepresentativeId: string;
  legalRepresentativesAccepted?: boolean;
  userLegalRepresentation: boolean;
  customersSoleSignatureAuthorized: boolean;
  dashboardActions?: IDashboardAction;
  allRequiredFilesUploaded?: boolean;
  compeonDocument?: CompeonDocument[];
  compeonState?: compeonExternalStateStepKeys;
  usageTypeCondition?: boolean;
  completedSignsCount?: number;
  finalSignedContract?: string;
  customerIdentificationType?: string;
  fesUrl?: string;
  fesStatus?: string;
  bfssLane?: string;
  beneficiaryOwners: IBeneficiaryOwner[];
  fileArchivingTriggeredAt?: Date | null;
  expirationInDays?: number;
  beneficialOwner?: boolean;
}

export interface IInquiryEditDetails {
  [FINANCING_NEED]: object;
  [COMPANY_DETAILS]: object;
  [REQUEST_DETAILS]: object;
  [USER_PROFILE]: object;
  [CONTACT_PERSON_DETAILS]: object;
}

export interface IIndicativeOfferCalculations {
  factoringFee: string;
  factoringLine: string | undefined;
  payoutRate: string;
}

export interface IDashboardAction {
  indicativeConditionCompleted?: boolean;
  indicativeOffer?: Partial<IIndicativeOffer>;
  indicativeCondition?: Partial<IIndicativeCondition>;
  indicativeOfferCalculations?: IIndicativeOfferCalculations;
  transparencyRegister?: ITransparencyRegister;
  kycTriggered?: boolean;
  customerInvitationSend?: boolean;
  customerInvitationAccepted?: boolean;
  crefoTriggered?: boolean;
  inconsistencyReportUrl?: string;
  marketDataSent?: boolean;
  assignedRealEstateExpert?: boolean;
  isQuickCheckStarted?: boolean;
  isKycProcessCompleted?: boolean;
}

export type InquiryDetailsType = IInquiryDetails;

export interface IInquiryComments {
  id: string;
  user: {
    firstName: string;
    lastName: string;
  };
  content: string;
  createdAt: string;
}

export interface IFastlaneState {
  areDocumentsMandatory: boolean;
  isFastlaneLoading: boolean;
  isQualifiedForFastlane: boolean;
  canDisplayFastlaneDocuments: boolean;
  fastlaneInquiryFileRequests: IFileRequest[];
  fastlaneErrors: object;
  errorCheckingFastlane: boolean;
}

export interface IIndicativeOffer {
  [OFFER_FACTORING_FEE]: string;
  [OFFER_FACTORING_LINE]: string;
  [OFFER_INSTALLMENT_RATE]: string;
  [OFFER_MONTHLY_INSTALLMENT]: string;
  [OFFER_PAYOUT_RATIO]: string;
}

export interface IIndicativeCondition {
  [CONDITION_INTEREST_RATE]: string;
  [CONDITION_LAST_INSTALLMENT_AMOUNT]: string;
  [CONDITION_INSTALLMENT_AMOUNT]: string;
}

export enum ReportType {
  COMPACT = 'compact',
  TRAFFIC_LIGHT = 'traffic_light',
  SHORT = 'short',
  RATING = 'rating',
  BLACK_WHITE = 'black_white',
}

export enum PayloadReportType {
  SHORT = 'short',
  RATING = 'rating',
  BLACK_WHITE = 'black_white',
}

type SimilarPayloadAndReportType = ReportType.TRAFFIC_LIGHT | ReportType.COMPACT;

export type PayloadType = SimilarPayloadAndReportType | PayloadReportType;

export interface CompeonDocument extends IDefaultFile {
  fileName: string;
  createdAt: Date | null;
}

export interface IUsageSpaceObject {
  id: string;
  [PROJECT_FINANCING_USAGE_KIND_TYPE]: string;
  [PROJECT_FINANCING__NUMBER_OF_UNITS]: number;
  [PROJECT_FINANCING_PERCENTAGE_OF_RENT]: number;
  [PROJECT_FINANCING_TYPE_OF_USAGE]: string;
  [PROJECT_FINANCING_USAGE_SPACE]: number;
}

export interface IInquiryFinancingDetailsFields {
  [index: string]: any;
  [FINANCING_AMOUNT]: string;
  [PROJECT_FINANCING__GUEST_BATHROOM]: boolean;
  [PROJECT_FINANCING__BALKONY]: boolean;
  [PROJECT_FINANCING__HAS_LIST_OF_BUILDING_ENCUMBRANCES]: boolean;
  [PROJECT_FINANCING__HAS_ENERGY_CERTIFICATE]: boolean;
  [PROJECT_FINANCING__CELLAR]: boolean;
  [PROJECT_FINANCING_LIST_OF_CONTAMINATED_SITES_EXIST]: false;
  [PROJECT_FINANCING__FURNISHING_QUALITY]: string;
  [PROJECT_FINANCING__NUMBER_OF_GARAGES]: number;
  [PROJECT_FINANCING_INVESTMENT_AMOUNT]: string;
  [PROJECT_FINANCING__LIFT]: boolean;
  [PROJECT_FINANCING_LIST_OF_RENTERS]: boolean;
  [PROJECT_FINANCING_LOT_SIZE]: number;
  [PROJECT_FINANCING_MEZZANINE]: string;
  [PROJECT_FINANCING_OWN_WORK]: string;
  [PROJECT_FINANCING_OBJECT_ADDRESS]: string;
  [PROJECT_FINANCING_OBJECT_DESCRIPTION]: string;
  [PROJECT_FINANCING_OBJECT_CITY]: string;
  [PROJECT_FINANCING__OBJECT_USAGE]: Array<IUsageSpaceObject>;
  [PROJECT_FINANCING_OBJECT_ZIP_CODE]: string;
  [PROJECT_FINANCING_OWN_FUNDS]: string;
  [PROJECT_FINANCING__NUMBER_OF_PARKING_LOTS]: boolean;
  [PROJECT_FINANCING__QUALITY_CONDITION]: string;
  [PROJECT_FINANCING_RENOVATION_PLANNED]: boolean;
  [PROJECT_FINANCING_RENTAL_CONTRACTS]: boolean;
  [PROJECT_FINANCING_ROLE]: string;
  [PROJECT_FINANCING_SUBORDINATED_CAPITAL]: string;
  [PROJECT_FINANCING__BUILDING_YEAR]: number;
  [PROJECT_FINANCING__MODERNIZATION_YEAR]: number;
  [PURPOSE_KIND]: string;
  usageTypeCondition: string;
}
