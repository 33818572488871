import React from 'react';

import { useSelector } from 'react-redux';

import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import CompanyDetailsSection from 'modules/InquiryDetails/DetailedInformation/CompanyDetailsSection/CompanyDetailsSection';
import CoronaDetailsSection from 'modules/InquiryDetails/DetailedInformation/CoronaDetailsSection/CoronaDetailsSection';
import FinancingPurposeSection from 'modules/InquiryDetails/DetailedInformation/FinancingPurposeSection/FinancingPurposeSection';
import InquiryDetailsSection from 'modules/InquiryDetails/DetailedInformation/InquiryDetailsSection';
import SummarySection from 'modules/InquiryDetails/DetailedInformation/SummarySection';
import UserProfileSection from 'modules/InquiryDetails/DetailedInformation/UserProfileSection/UserProfileSection';
import VbProfileSection from 'modules/InquiryDetails/DetailedInformation/VbProfileSection/index';
import InquiryRevision from 'modules/InquiryDetails/InquiryRevision/InquiryRevision';
import InquiryOffers from 'modules/Offers/InquiryOffersSection';
import AdditionalFinancingSection from 'pages/customerPortal/InquiryDetails/DetailedInformation/AdditionalFinancingSection/ForwardToCompeonAcceptance';
import { chooseSelectedInquiryTypeSpecificValue } from 'shared/chooseSelectedInquiryTypeSpecificComponent';
import { hasOffersSelector } from 'store/inquiryDetails/selectors';

import FinancingOfferSection from './FinancingOfferSection/FinancingOfferSection';

const DefaultSections = () => {
  const hasOffers = useSelector(hasOffersSelector);
  const coreSections = chooseSelectedInquiryTypeSpecificValue({
    [InquiryType.bfsService]: [
      <FinancingPurposeSection />,
      <CompanyDetailsSection />,
      <FinancingOfferSection />,
      <UserProfileSection />,
    ],
    [InquiryType.default]: [
      <FinancingPurposeSection />,
      <UserProfileSection />,
      <CompanyDetailsSection />,
      <InquiryDetailsSection />,
    ],
  });

  return (
    <>
      <AdditionalFinancingSection />
      {hasOffers && <InquiryOffers />}
      <SummarySection />
      <CoronaDetailsSection />
      {coreSections}
      <VbProfileSection />
      <InquiryRevision />
    </>
  );
};

export default DefaultSections;
