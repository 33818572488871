import React from 'react';

import { useLanguages } from 'components/App/LanguageProvider/useLanguages';
import { useFetchInquiryFileRequests } from 'shared/documentExchange/useFetchInquiryFileRequests';

import { LeasePlanSummaryCard } from './LeasePlanSummaryCard';
import ProgressSectionsWrapper from './ProgressSectionsWrapper';
import { ProgressSuccess } from './ProgressSuccess';
import { useProgressSections } from './useProgressSections';

import { SectionByType } from '.';

const LeaseplanCustomerDashboard = () => {
  const { selectedLanguage } = useLanguages();
  const { isLoading: requestsIsLoading } = useFetchInquiryFileRequests({
    selectedLanguage,
  });
  const { firstOpenTask, completedValue, sectionsDone, sectionsOpen, sections, lastItemIndex } =
    useProgressSections();

  const header = (
    <LeasePlanSummaryCard
      completedValue={completedValue}
      progressIncrement={sectionsDone}
      progressTotal={sectionsDone + sectionsOpen}
      isCompleted={sectionsOpen === 0}
      openTaskId={firstOpenTask}
    />
  );

  const progressSections = (
    <>
      {sections &&
        Object.values(sections)
          .filter((item) => item.visible)
          .map((section, index) => {
            const sectionProps = {
              completedValue: section.completedValue,
              isFirst: index === 0,
              isLast: sectionsOpen !== 0 && index === lastItemIndex,
              isCompleted: completedValue === 100,
              isPending: section.isPending,
              progressIncrement: section.increment,
              actionUrl: section.data?.url,
              disableButton: section.data?.disableButton,
              progressTotal: section.total,
              id: firstOpenTask === section.type ? firstOpenTask : undefined,
            };
            return <SectionByType sectionProps={sectionProps} section={section} />;
          })}
      {sectionsOpen === 0 && (
        <ProgressSuccess
          isLast
          isCompleted
          completedValue={100}
          progressIncrement={1}
          progressTotal={1}
        />
      )}
    </>
  );

  return (
    <ProgressSectionsWrapper
      header={header}
      progressSections={progressSections}
      isLoading={requestsIsLoading}
    />
  );
};

export default LeaseplanCustomerDashboard;
