import React, { useCallback, useEffect, useState } from 'react';

import { Box } from '@chakra-ui/react';
import { FormApi } from 'final-form';
import { Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
  useLoadProfitabilityData,
  useSubmitProfitability,
} from 'api/CompeonReverseApi/operation/queryHooks';
import FormSection from 'components/FormSection';
import paths from 'constants/paths';
import { mapRLLProfitabilityFromApi } from 'modules/EditMarketValuesReeProfitability/mapRLLProfitabilityFromAPI';
import { mapRLLQuickCheckToAPI } from 'modules/EditMarketValuesReeProfitability/mapRLLProfitabilityToAPI';
import BottomBar from 'modules/FormPage/BottomBar';
import {
  QUICK_CHECK_ROLES_FILE_HALTER,
  QUICK_CHECK_ROLES_PARTITION,
  QUICK_CHECK_ROLES_PROPERTY_DEVELOPER,
  QUICK_CHECK_SELECTING_PROFITABILITY_TYPE,
} from 'modules/Inquiry/Form/formFields';
import ButtonsWrapper from 'pages/inquiryFlow/FinalPage/SuccessButtons/ButtonsWrapper';
import { isQuickCheckStartedAction } from 'store/inquiryDetails/actions';
import { getInquiryIdSelector } from 'store/inquiryDetails/selectors';
import ButtonComponent from 'theme/components/Button';
import ArrowRightIcon from 'theme/components/Icon/ArrowRightIcon';
import SaveIcon from 'theme/components/Icon/SaveIcon';
import { useTranslations } from 'utils/hooks/useTranslations';
import { deepCamelcaseKeys } from 'utils/object';

import CreditValueSection from './CreditValueSection';
import { useDownloadPdfFile } from './hooks';
import InformationAboutCustomerSection from './InformationAboutCustomerSection';
import OtherInformationSection from './OtherInformationSection';
import ProjectCalculationSection from './ProjectCalculationSection';
import ProjectInformationSection from './ProjectInformationSection';
import UnsecuredPortionSection from './UnsecuredPortionSection';
import EditRealStateHeading from '../EditMarketValuesReeProfitability/EditRealStateHeading';

const sections: {
  component: JSX.Element;
  title: string;
}[] = [
  {
    component: <InformationAboutCustomerSection />,
    title: 'sections.informationAboutCustomer.title',
  },
  {
    component: <ProjectInformationSection />,
    title: 'sections.projectInformation.title',
  },
  {
    component: <OtherInformationSection />,
    title: 'sections.otherInformation.title',
  },
  {
    component: <CreditValueSection />,
    title: 'sections.creditValue.title',
  },
  {
    component: <UnsecuredPortionSection />,
    title: 'sections.unsecuredPortion.title',
  },
  {
    component: <ProjectCalculationSection />,
    title: 'sections.projectCalculation.title',
  },
];

enum Role {
  DEVELOPER = 'developer',
  PARTITION = 'partition',
  STOCKHOLDER = 'stockholder',
}

enum Variant {
  CURRENT = 'current',
  FUTURE = 'future',
}

const ROLES_MAP = {
  [QUICK_CHECK_ROLES_FILE_HALTER]: Role.STOCKHOLDER,
  [QUICK_CHECK_ROLES_PARTITION]: Role.PARTITION,
  [QUICK_CHECK_ROLES_PROPERTY_DEVELOPER]: Role.DEVELOPER,
};

const useHandleSubmit = (inquiryId: string) => {
  const updateDataMutation = useSubmitProfitability();
  const { downloadPdf } = useDownloadPdfFile();
  const [isPending, setIsPending] = React.useState(false);

  const handleSubmit = async (data: Record<string, any>) => {
    try {
      const mappedPayload = mapRLLQuickCheckToAPI(data);
      await updateDataMutation.mutateAsync({ data: mappedPayload, inquiryId });
    } catch (error) {
      throw new Error(error as string);
    }
  };

  const handleDownloads = async (data: Record<string, unknown>) => {
    const requests = Object.keys(ROLES_MAP)
      .filter((roleKey) => data[roleKey])
      .map((roleKey) =>
        downloadPdf(
          ROLES_MAP[roleKey as keyof typeof ROLES_MAP],
          data[QUICK_CHECK_SELECTING_PROFITABILITY_TYPE] as Variant,
        ),
      );
    setIsPending(true);
    await Promise.all(requests);
    setIsPending(false);
  };

  return { handleDownloads, handleSubmit, isPending };
};

const QuickCheck = () => {
  const t = useTranslations('pages.planningEvaluation.quickCheck');
  const inquiryId = useSelector(getInquiryIdSelector) || '';
  const [initialValues, setInitialValues] = useState({});
  const history = useHistory();
  const dispatch = useDispatch();

  const { data, isError } = useLoadProfitabilityData({
    variables: { inquiryId },
    select: (response) => ({
      initialValues: mapRLLProfitabilityFromApi(
        deepCamelcaseKeys(response.data.attributes.details),
      ),
    }),
  });

  const getInitialValues = useCallback(() => {
    if (!isError) {
      setInitialValues(data?.initialValues || {});
      dispatch(isQuickCheckStartedAction());
    } else {
      history.goBack();
    }
  }, [dispatch, history, data, isError]);

  const { handleDownloads, handleSubmit, isPending } = useHandleSubmit(inquiryId);

  const onSaveClick = async (form: FormApi) => {
    await form.submit();
    if (form.getState().valid) navigateToDashboard();
  };

  const onDownloadClick = async (form: FormApi) => {
    await form.submit();

    if (!form.getState().valid) return;

    await handleDownloads(form.getState().values);
    navigateToDashboard();
  };

  const navigateToDashboard = () => {
    history.push(paths.operation.inquiryDetails.dashboard.replace(':id', inquiryId));
  };

  useEffect(() => {
    getInitialValues();
  }, [getInitialValues]);

  return (
    <Box>
      <Form
        onSubmit={handleSubmit}
        initialValues={initialValues}
        render={({ form }) => (
          <form>
            <EditRealStateHeading heading={t('pageTitle')} subHeading={t('subheading')} />
            {sections.map(({ component, title }, index) => (
              <FormSection sectionNumber={index + 1} title={t(title)}>
                {component}
              </FormSection>
            ))}
            <BottomBar>
              <ButtonsWrapper>
                <ButtonComponent
                  variant="tertiary"
                  mr={4}
                  data-testid={'cancel'}
                  onClick={navigateToDashboard}
                >
                  {t('actions.cancel')}
                </ButtonComponent>
                <ButtonComponent
                  leftIcon={<SaveIcon boxSize={6} display="block" />}
                  mr={4}
                  onClick={() => onSaveClick(form)}
                  data-testid="saveChanges"
                  isLoading={isPending}
                  disabled={isPending}
                >
                  {t('actions.saveChanges')}
                </ButtonComponent>
                <ButtonComponent
                  leftIcon={<ArrowRightIcon boxSize={6} display="block" />}
                  data-testid={'downloadPdfFile'}
                  onClick={() => onDownloadClick(form)}
                  isLoading={isPending}
                  disabled={isPending}
                >
                  {t('actions.downloadPdfFile')}
                </ButtonComponent>
              </ButtonsWrapper>
            </BottomBar>
          </form>
        )}
      />
    </Box>
  );
};

export default QuickCheck;
