import React, { useEffect } from 'react';

import { createForm } from 'final-form';
import { useForm } from 'react-final-form';

import FormSection from 'components/FormSection';
import { fieldIsValid } from 'modules/Inquiry/Form/formConditions';
import { PURPOSE_KIND } from 'modules/Inquiry/Form/formFields';
import { PurposeKindAdapterWithField } from 'modules/Inquiry/Form/Steps/FinancingNeed/Purpose/PurposeKind';
import usePurposeKindOptions from 'modules/Inquiry/Form/Steps/FinancingNeed/Purpose/usePurposeKindOptions';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { FormSections } from 'schema/inquirySchema.models';
import { chooseSelectedInquiryTypeSpecificComponent } from 'shared/chooseSelectedInquiryTypeSpecificComponent';
import { useTranslations } from 'utils/hooks/useTranslations';

import BfsServicePurposeSection from './BfsServicePurposeSection';

interface PurposeSectionFormValues {
  [PURPOSE_KIND]: {
    value: string;
    data: { onlyOneOption?: boolean };
  };
}

export const validatePurposeSection = (
  form: ReturnType<typeof createForm<PurposeSectionFormValues>>,
) => {
  const fieldState = form.getFieldState(PURPOSE_KIND);

  if (fieldState && fieldState.data?.onlyOneOption) {
    // don't validate field if only one option exists
    return undefined;
  }
  return () => !fieldState?.pristine && fieldIsValid(PURPOSE_KIND)({ form });
};

const PurposeSection = () => {
  const t = useTranslations('pages.financingNeed.sections.purpose');
  const purposes = usePurposeKindOptions();
  const {
    change,
    registerField,
    mutators: { setFieldData },
  } = useForm();

  useEffect(() => {
    if (purposes.length <= 1) {
      change(PURPOSE_KIND, purposes[0].key);
      registerField(PURPOSE_KIND, () => {}, { value: true });
      setFieldData(PURPOSE_KIND, { onlyOneOption: true });
    }
  }, [change, registerField, setFieldData, purposes]);

  if (purposes.length <= 1) {
    return null;
  }

  return (
    <FormSection title={t('title')} name={FormSections.purposeKind}>
      <PurposeKindAdapterWithField name={PURPOSE_KIND} purposes={purposes} />
    </FormSection>
  );
};

export default chooseSelectedInquiryTypeSpecificComponent({
  [InquiryType.bfsService]: BfsServicePurposeSection,
  default: React.memo(PurposeSection),
});
