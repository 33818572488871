import { toLower as _toLower } from 'lodash';
import intersection from 'lodash/intersection';
import _isObject from 'lodash/isObject';

import {
  USER_PROFILE_TURNOVER_CLASS__100_TO_250_THOUSAND,
  USER_PROFILE_TURNOVER_CLASS__250_TO_500_THOUSAND,
  USER_PROFILE_TURNOVER_CLASS__500_THOUSAND_TO_1_MILLIOND,
  USER_PROFILE_TURNOVER_CLASS__1_MILLION_TO_2_5_MILLION,
  USER_PROFILE_TURNOVER_CLASS__2_5_TO_5_MILLION,
  USER_PROFILE_TURNOVER_CLASS__5_TO_10_MILLION,
  USER_PROFILE_TURNOVER_CLASS__10_TO_25_MILLION,
  USER_PROFILE_TURNOVER_CLASS__25_TO_50_MILLION,
  USER_PROFILE_TURNOVER_CLASS__50_TO_100_MILLION,
  USER_PROFILE_TURNOVER_CLASS__MORE_THAN_100_MILLION,
  USER_PROFILE_TURNOVER_CLASS__0_TO_10_THOUSAND,
  USER_PROFILE_TURNOVER_CLASS__10_TO_20_THOUSAND,
  USER_PROFILE_TURNOVER_CLASS__20_TO_30_THOUSAND,
  USER_PROFILE_TURNOVER_CLASS__30_TO_40_THOUSAND,
  USER_PROFILE_TURNOVER_CLASS__40_TO_50_THOUSAND,
  USER_PROFILE_TURNOVER_CLASS__50_TO_75_THOUSAND,
  USER_PROFILE_TURNOVER_CLASS__75_TO_100_THOUSAND,
} from 'modules/Inquiry/Form/formFields';

export const truncate = (str, n) => (str.length > n ? `${str.substr(0, n - 1)} ...` : str);

export const flattenObject = (myObject) =>
  Object.entries(myObject).reduce((acc, [key, value]) => {
    if (typeof value === 'object') {
      return { ...acc, ...value };
    }
    return { ...acc, [key]: value };
  }, {});

export const isEmptyObject = (value) =>
  typeof value === 'object' && Object.keys(value).length === 0;
const isObject = (someValue) => typeof someValue === 'object' && someValue !== null;

export const allObjectsContainValues = (arrOfObjects) => {
  if (!Array.isArray(arrOfObjects)) {
    return false;
  }

  const objectsWithoutValues = (dataObject) => !isObject(dataObject) || isEmptyObject(dataObject);

  return arrOfObjects.filter(objectsWithoutValues).length === 0;
};

export const pipe =
  (...fns) =>
  (args) =>
    fns.reduce((v, f) => f(v), args);

export const doArraysHaveCommonPart = (array1, array2) =>
  Boolean(intersection(array1, array2).length);

export const filterObjectKeys = (keysToFilter, obj) =>
  Object.keys(obj)
    .filter((key) => !keysToFilter.includes(key))
    .reduce((result, key) => ({ ...result, [key]: obj[key] }), {});

export const filterObjectEmptyKeys = (obj) => {
  if (!obj) {
    return {};
  }
  return Object.keys(obj)
    .filter((key) => obj[key])
    .reduce((result, key) => ({ ...result, [key]: obj[key] }), {});
};

export const objHasKeys = (obj) => Object.keys(obj).length;

export const sortObjByKeyList = (obj, keysOrder) =>
  keysOrder.reduce((accum, key) => ({ ...accum, [key]: obj[key] }), {});

export const removeObjectValues = (obj, condition) => {
  if (!obj) {
    return {};
  }
  return Object.entries(obj).reduce((result, [key, value]) => {
    if (condition(value)) {
      return result;
    }
    if (_isObject(value) && !Array.isArray(value)) {
      return { ...result, [key]: removeObjectValues(value, condition) };
    }

    return { ...result, [key]: value };
  }, {});
};

export const turnoverClassFor = (value) => {
  switch (true) {
    case value > 0 && value <= 10000:
      return USER_PROFILE_TURNOVER_CLASS__0_TO_10_THOUSAND;
    case value > 10000 && value <= 20000:
      return USER_PROFILE_TURNOVER_CLASS__10_TO_20_THOUSAND;
    case value > 20000 && value <= 30000:
      return USER_PROFILE_TURNOVER_CLASS__20_TO_30_THOUSAND;
    case value > 30000 && value <= 40000:
      return USER_PROFILE_TURNOVER_CLASS__30_TO_40_THOUSAND;
    case value > 40000 && value <= 50000:
      return USER_PROFILE_TURNOVER_CLASS__40_TO_50_THOUSAND;
    case value > 50000 && value <= 75000:
      return USER_PROFILE_TURNOVER_CLASS__50_TO_75_THOUSAND;
    case value > 75000 && value <= 100000:
      return USER_PROFILE_TURNOVER_CLASS__75_TO_100_THOUSAND;
    case value > 100000 && value <= 250000:
      return USER_PROFILE_TURNOVER_CLASS__100_TO_250_THOUSAND;
    case value > 250000 && value <= 500000:
      return USER_PROFILE_TURNOVER_CLASS__250_TO_500_THOUSAND;
    case value > 500000 && value <= 1000000:
      return USER_PROFILE_TURNOVER_CLASS__500_THOUSAND_TO_1_MILLIOND;
    case value > 1000000 && value <= 2500000:
      return USER_PROFILE_TURNOVER_CLASS__1_MILLION_TO_2_5_MILLION;
    case value > 2500000 && value <= 5000000:
      return USER_PROFILE_TURNOVER_CLASS__2_5_TO_5_MILLION;
    case value > 5000000 && value <= 10000000:
      return USER_PROFILE_TURNOVER_CLASS__5_TO_10_MILLION;
    case value > 10000000 && value <= 25000000:
      return USER_PROFILE_TURNOVER_CLASS__10_TO_25_MILLION;
    case value > 25000000 && value <= 50000000:
      return USER_PROFILE_TURNOVER_CLASS__25_TO_50_MILLION;
    case value > 50000000 && value <= 100000000:
      return USER_PROFILE_TURNOVER_CLASS__50_TO_100_MILLION;
    case value > 100000000:
      return USER_PROFILE_TURNOVER_CLASS__MORE_THAN_100_MILLION;
    default:
      return '';
  }
};

export const convertLocalToUTCDate = (date) => {
  if (!date) {
    return date;
  }
  const newDate = new Date(date);
  const utcDate = new Date(Date.UTC(newDate.getFullYear(), newDate.getMonth(), newDate.getDate()));
  return utcDate;
};

export const parseStringBoolean = (value) => {
  if (typeof value === 'boolean') {
    return value;
  }
  if (typeof value === 'string') {
    return _toLower(value) === 'true';
  }
  return value;
};
