import env from '@beam-australia/react-env';
import { useSelector } from 'react-redux';

import { usePartnerConfig } from 'config/partner/hooks';
import { generatePdfContent } from 'modules/Offers/InquiryOffersSection/CompeonOffers/pdfGenerator/pdfLayout';
import { getInquiryCustomerSelector, getInquiryIdSelector } from 'store/inquiryDetails/selectors';
import { getBase64ImageFromURL } from 'utils/base64FromImgUrl';
import { useStyledTheme } from 'utils/hooks/useStyledTheme';
import { useTranslations } from 'utils/hooks/useTranslations';
import { getParsedItemFromSessionStorage } from 'utils/sessionStorage/helpers';

import { isPartnerValueAdded, getPartnerTypeName } from '../OfferBox/OfferBox';

const FONT_SETTINGS = {
  Ubuntu: {
    normal: 'Ubuntu-Regular.ttf',
    bold: 'Ubuntu-Bold.ttf',
  },
};

const importPdfLibrary = () => import('pdfmake');
const importFont = () => import('assets/ubuntuFont');

const setDefaultFontSettings = (pdfMake, font) => {
  pdfMake.vfs = font.default.pdfMake.vfs; // eslint-disable-line no-param-reassign
  pdfMake.fonts = FONT_SETTINGS; // eslint-disable-line no-param-reassign
};

const mapWithoutEmptyProperties = (offer) => ({
  ...offer,
  properties: offer.properties.filter((prop) => prop.value !== null),
});

const buildCustomerHeadline = (translator, customerData) =>
  `${translator('yourVb')} ${customerData.firstName} ${customerData.lastName} ${
    customerData.phoneNumber
  }`;

export const useOffersPdfGenerator = (offers) => {
  const {
    meta: { logoUrl },
  } = usePartnerConfig();
  const inquiryId = useSelector(getInquiryIdSelector);
  const showBasisOffers = getParsedItemFromSessionStorage(`showBasisPartners-${inquiryId}`);

  const translate = useTranslations('pages.inquiryDetails.offers.compeon');
  const theme = useStyledTheme();
  const pdfTitle = translate('pdf.headline');

  const customerInfo = useSelector(getInquiryCustomerSelector);
  const customerHeadline = buildCustomerHeadline(translate, customerInfo);
  const onlyPremiumOffers = offers.filter((offer) => offer.fromPremiumPartner);
  const compeonOffers = showBasisOffers || !onlyPremiumOffers.length ? offers : onlyPremiumOffers;

  const offersMappedForPDF = compeonOffers.map(mapWithoutEmptyProperties).map((offer) => {
    return {
      ...offer,
      partnerType: {
        label: '',
        value: translate('partner.' + getPartnerTypeName(offer)),
        highlightLabel: isPartnerValueAdded(offer),
      },
      productName: {
        label: translate('properties.product.caption'),
        value: translate(`properties.product.options.${offer.productName}`),
      },
      requiredFiles: {
        label: translate('properties.product.requiredFiles'),
        value: [...offer.requiredFiles, ...offer.filesRequiredByBank],
      },
    };
  });

  const footerContents = [translate('footer1'), translate('footer2'), translate('copyrights')];

  return async () => {
    const [pdfMakeLib, ubuntuFont] = await Promise.all([importPdfLibrary(), importFont()]);

    const urlToLogo = `${env('LOGO_STORAGE_URL')}${logoUrl}`;
    const logoInB64 = await getBase64ImageFromURL(urlToLogo);

    const pdfMake = pdfMakeLib.default;
    setDefaultFontSettings(pdfMake, ubuntuFont);
    pdfMake
      .createPdf(
        generatePdfContent({
          offers: offersMappedForPDF,
          title: pdfTitle,
          logo: logoInB64,
          colorTheme: theme,
          headline: customerHeadline,
          footer: footerContents,
        }),
      )
      .download(translate('offersOverview'));
  };
};
