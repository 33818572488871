import React from 'react';

import { Box } from '@chakra-ui/react';

import FormRow from 'components/FormRow';
import FormSectionLayout from 'components/FormSection/FormSectionLayout';
import { SelectWithField } from 'components/Selects/Select';
import { useFieldValidators } from 'shared/hooks/useFieldValidators';
import { useFieldValue } from 'store/hooks/useFormValues';
import { AlertComponent } from 'theme/components/Alert';
import { TextComponent } from 'theme/components/Text';
import { useTranslations } from 'utils/hooks/useTranslations';

import { useAssociatedPersons, usePeopleToMergeSelectOptions } from './useAssociatedPersons';
import { textStyle } from '../../../../theme/themeConstants';

export enum SourceType {
  KYC = 'kyc_now',
  CREFO = 'crefo',
  USER_INPUT = 'user_input',
}

export const KYC_OPTION = 'kyc-option';
export const CREFO_OPTION = 'creditReform-option';
export const NO_ONE_OPTION = 'no_one';

interface AssociatedPersonMergeSectionsProps {
  personId: string;
}
const AssociatedPersonMergeSections: React.FC<AssociatedPersonMergeSectionsProps> = ({
  personId,
}: AssociatedPersonMergeSectionsProps) => {
  const t = useTranslations('pages.companiesDetails.associatedPerson.merge');
  const { persons, peopleToMerge } = useAssociatedPersons(personId);
  const peopleToMergeOptions = usePeopleToMergeSelectOptions(peopleToMerge);
  const peopleKyc = peopleToMergeOptions.filter((po: any) => po.value.source === SourceType.KYC);
  const peopleCrefo = peopleToMergeOptions.filter(
    (po: any) => po.value.source === SourceType.CREFO,
  );
  const kycOption = useFieldValue<unknown>(KYC_OPTION, null);
  const crefoOption = useFieldValue<unknown>(CREFO_OPTION, null);
  const { required } = useFieldValidators();

  const noOneOption = {
    value: NO_ONE_OPTION,
    label: t('noOne'),
    complexLabel: <i>{t('noOne')}</i>,
  };

  return (
    <>
      {persons.map((person: any, index: number) => (
        <FormSectionLayout sectionNumber={index + 1} title={t('sectionTitle')}>
          <TextComponent mb={12} color="text.tertiary">
            {t('sectionDescription')}
          </TextComponent>

          {/* ToDo: change this to DescriptionList component when other PR 1833 is merged */}
          <Box mb={8}>
            <TextComponent mb={1} {...textStyle.sizeM} color="text.tertiary">
              {`${t('personCaption')}${t('personCaptionType.manual')}`}
            </TextComponent>

            <TextComponent color="text.secondary" {...textStyle.sizeL}>
              {`${person.lastName}, ${person.firstName} geb. ${person.birthDate ?? '-'}`}
            </TextComponent>
          </Box>

          {peopleKyc.length > 0 && (
            <FormRow>
              <Box>
                <SelectWithField
                  name={KYC_OPTION}
                  validate={required}
                  caption={`${t('personCaption')}${t('personCaptionType.kyc')}`}
                  options={[...peopleKyc, noOneOption]}
                  data-testid={'select-field-' + KYC_OPTION}
                />
                {kycOption === NO_ONE_OPTION && (
                  <AlertComponent status="warning">{t('noOneKyc')}</AlertComponent>
                )}
              </Box>
            </FormRow>
          )}
          {peopleCrefo.length > 0 && (
            <FormRow separator={false}>
              <Box>
                <SelectWithField
                  name={CREFO_OPTION}
                  validate={required}
                  caption={`${t('personCaption')}${t('personCaptionType.creditReform')}`}
                  options={[...peopleCrefo, noOneOption]}
                  data-testid={'select-field-' + CREFO_OPTION}
                />
                {crefoOption === NO_ONE_OPTION && (
                  <AlertComponent status="warning">{t('noOneCrefo')}</AlertComponent>
                )}
              </Box>
            </FormRow>
          )}
        </FormSectionLayout>
      ))}
    </>
  );
};

export default AssociatedPersonMergeSections;
