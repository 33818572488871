import React from 'react';

import PropTypes from 'prop-types';

import { Asset } from 'components/Asset';
import {
  StyledGenderButton,
  StyledIconWrapper,
  StyledButtonWrapper,
} from 'components/GenderButtons/styles';
import FieldGroup from 'components/PortalFormFields/FieldGroup';
import {
  GENDER_MAN,
  GENDER_WOMAN,
  COMPANY_DETAILS_GENDER__MAN,
  COMPANY_DETAILS_GENDER__WOMAN,
} from 'modules/Inquiry/Form/formFields';
import { FinalFormFieldPropTypes } from 'utils/form/propTypes';
import withFormField from 'utils/form/withFormField';
import { useTranslations } from 'utils/hooks/useTranslations';

const GenderButtons = ({ value, onChange, label, error, name, disabled = false }) => {
  const t = useTranslations(
    'pages.companyDetails.sections.contactPerson.fields.companyDetailsGender.genders',
  );

  const handleGenderClick = (genderValue) => () => onChange(genderValue);
  return (
    <FieldGroup label={label} error={error}>
      <StyledButtonWrapper data-testId={name}>
        <StyledGenderButton
          data-testid={COMPANY_DETAILS_GENDER__WOMAN}
          type="button"
          name={name}
          className={value === GENDER_WOMAN && 'active'}
          onClick={handleGenderClick(GENDER_WOMAN)}
          disabled={disabled}
        >
          <StyledIconWrapper>
            <Asset type="gender" value={COMPANY_DETAILS_GENDER__WOMAN} boxSize="8" />
          </StyledIconWrapper>
          {t('woman')}
        </StyledGenderButton>

        <StyledGenderButton
          data-testid={COMPANY_DETAILS_GENDER__MAN}
          type="button"
          className={value === GENDER_MAN && 'active'}
          onClick={handleGenderClick(GENDER_MAN)}
          disabled={disabled}
        >
          <StyledIconWrapper>
            <Asset type="gender" value={COMPANY_DETAILS_GENDER__MAN} boxSize="8" />
          </StyledIconWrapper>
          {t('man')}
        </StyledGenderButton>
      </StyledButtonWrapper>
    </FieldGroup>
  );
};

GenderButtons.defaultProps = {
  value: null,
  error: null,
  name: null,
};

GenderButtons.propTypes = {
  value: PropTypes.oneOf([GENDER_MAN, GENDER_WOMAN, '']),
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  error: PropTypes.string,
  name: PropTypes.string,
};

const GenderAdapter = ({ input, meta, ...rest }) => (
  <GenderButtons
    value={input.value}
    onChange={input.onChange}
    error={meta.touched ? meta.error : ''}
    label={rest.caption}
    name={input.name}
    {...rest}
  />
);
GenderAdapter.propTypes = FinalFormFieldPropTypes;

export const GenderWithField = withFormField(GenderAdapter);
