import * as React from 'react';

import { Divider, SimpleGrid } from '@chakra-ui/react';

import { DetailField } from './DetailField';
import { SummaryPageField } from '../../../../../../new/summary/hooks/useBuildFieldsForSummary';

type GridWithDividerProps = {
  fields: Array<SummaryPageField>;
};

export const GridWithDivider = ({ fields }: GridWithDividerProps) => {
  const columns = 3;
  const itemsCount = fields.length;
  const rows = Math.ceil(itemsCount / columns);

  return (
    <SimpleGrid columns={columns} flexGrow={1} gap={12} w={'full'}>
      <>
        {fields.map((summaryPageField, index) => {
          const isLastItemInRow = (index + 1) % columns === 0;
          const isLastRow = Math.floor(index / columns) === rows - 1;
          const shouldAddDivider = isLastItemInRow && !isLastRow;

          return (
            <>
              <DetailField detailField={summaryPageField} />
              {shouldAddDivider ? (
                <Divider
                  orientation="horizontal"
                  gridColumn="span 3"
                  my={4}
                  borderColor="background.darkGrey"
                />
              ) : null}
            </>
          );
        })}
      </>
    </SimpleGrid>
  );
};
