import React, { useCallback } from 'react';

import { debounce as _debounce } from 'lodash';
import _get from 'lodash/get';
import { useFormState } from 'react-final-form';
import { createMutation } from 'react-query-kit';
import { useDispatch, useSelector } from 'react-redux';

import { EcoBankingAxiosClientAuthedInstance } from 'api';
import endpoints from 'api/CompeonReverseApi/endpoints';
import { InputWithField } from 'components/Input';
import AvailableEmailSymbol from 'modules/AvailableEmail/AvailableEmailSymbol';
import EmailNotAvailable from 'modules/AvailableEmail/EmailNotAvailable';
import { translations } from 'new/form/common/types';
import { useFieldValidators } from 'shared/hooks/useFieldValidators';
import { setIsEmailAvailable, setIsEmailLoading } from 'store/emailAvailable/actions';
import {
  isEmailAvailableSelector,
  isLoadingEmailAvailabilitySelector,
} from 'store/emailAvailable/selectors';
import { useTranslations } from 'utils/hooks/useTranslations';
import { isEmailRegex } from 'utils/regexes';
import { combineValidators } from 'utils/validators';

const useCombinedValidator = (syncValidate: any) => {
  // lastEmailValidaiton is used to keep the last validation result (undefined or syncErrors) in state to avoid calling the validation and endpoint again for the same value
  const [lastEmailValidation, setLastEmailValidation] = React.useState<{
    value: undefined | string;
    lastReturn: any;
  }>({ value: undefined, lastReturn: undefined });
  const dispatch = useDispatch();

  const checkEmailMutation = useCheckEmailMutation();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const checkEmailAvailability = useCallback(
    _debounce(async (email: string | undefined) => {
      if (!email) return undefined;

      try {
        const response = await checkEmailMutation.mutateAsync({ email });
        if (response.status === 200) {
          dispatch(setIsEmailLoading(false));
          dispatch(setIsEmailAvailable(false));
        }
      } catch (error: any) {
        dispatch(setIsEmailLoading(false));
        if (error.response.status === 404) {
          dispatch(setIsEmailAvailable(true));
        }
        console.error(error);
      }
    }, 200),
    [checkEmailMutation.mutateAsync, setIsEmailAvailable, setIsEmailLoading],
  );

  const validate = async (value: any, allValues: any) => {
    if (value === lastEmailValidation.value) {
      return lastEmailValidation.lastReturn;
    }
    dispatch(setIsEmailLoading(true));

    const syncErrors = syncValidate(value, allValues);
    if (syncErrors) {
      dispatch(setIsEmailLoading(false));
      setLastEmailValidation({ value, lastReturn: syncErrors });
      return syncErrors;
    }

    const available = await checkEmailAvailability(value);
    setLastEmailValidation({ value, lastReturn: available });
    return available;
  };

  return { validate };
};

const useCheckEmailMutation = createMutation({
  mutationFn: async (variables: { email: string | undefined }) => {
    return EcoBankingAxiosClientAuthedInstance.post(endpoints.USERS.EMAIL_AVAILABILITY.compose(), {
      email: variables.email,
    });
  },
});

type Props = {
  name: string;
  shouldShowEmailIcon?: boolean;
};

export const ContactPersonEmail = ({ name, shouldShowEmailIcon = false }: Props) => {
  const { required, isEmail } = useFieldValidators();
  const t = useTranslations();
  const { values } = useFormState();
  const emailValue = values && _get(values, name);
  const isLoading = useSelector(isLoadingEmailAvailabilitySelector);
  const isEmailAvailable = useSelector(isEmailAvailableSelector);

  const { validate: combinedValidator } = useCombinedValidator(
    combineValidators(required, isEmail),
  );

  const shouldShowLoginLink =
    !isLoading && !isEmailAvailable && emailValue && isEmailRegex.test(emailValue);

  const showEmailIcon = !!emailValue && isEmailRegex.test(emailValue) && shouldShowEmailIcon;

  return (
    <div>
      <InputWithField
        name={name}
        validate={combinedValidator}
        validateFields={[name]}
        type={'email'}
        caption={t(
          translations.pages.companyDetails.sections.contactPerson.fields.companyDetailsEmail
            .caption,
        )}
        sideSymbol={() =>
          showEmailIcon && (
            <AvailableEmailSymbol
              isLoading={isLoading}
              isAllowedToInquiry={combinedValidator(emailValue, values)}
            />
          )
        }
      />
      {shouldShowLoginLink && <EmailNotAvailable email={emailValue} />}
    </div>
  );
};
