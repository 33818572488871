import React from 'react';

import { Grid } from '@chakra-ui/react';

import { HiddenInputWithField } from 'components/HiddenInput';
import { hausbankFields } from 'hausbank/inquiry/fields';
import { hausbankPurposeKinds } from 'hausbank/inquiry/steps/financingNeed/sections/purposeSection/purposeKinds/purposeKinds';

import { PurposeKindItem } from './PurposeKindItem';

export const HausbankPurposeKind = () => {
  const { purposeKind } = hausbankFields.financingNeedPage.purposeSection;

  return (
    <>
      <HiddenInputWithField name={purposeKind} />
      <Grid
        templateColumns={{
          base: 'repeat(1, 1fr)',
          md: 'repeat(2, 1fr)',
          lg: 'repeat(3, 1fr)',
        }}
        gap={12}
      >
        {Object.values(hausbankPurposeKinds)
          .sort((a, b) => a.order - b.order)
          .map((purposeKind) => {
            return (
              <PurposeKindItem purposeKindItem={purposeKind} key={purposeKind.translationString} />
            );
          })}
      </Grid>
    </>
  );
};
