import { EcoBankingAxiosClientAuthedInstance } from 'api';
import { IInquiryListOptions } from 'models/InquiryList.model';
import { IInquiriesList } from 'models/InquiryList.model';
import { ProcessLog } from 'models/ProcessLog.model';
import {
  InquiryRevision,
  InquiryRevisionListElement,
} from 'modules/InquiryDetails/InquiryRevision/InquiryRevision.model';
import { mapInquiriesResponse, mapCountersToTabs } from 'modules/InquiryTable/mapFromApi';

import { tableOptionsToQueryParams } from './helpers';
import {
  TMarketDataResponse,
  IProfitabilityResponse,
  IReeMarketDataResponse,
  IInternalCommentsResponse,
  URLQuery,
} from './types';
import endpoints from '../endpoints';

const axiosInstance = EcoBankingAxiosClientAuthedInstance;

class OperationsApiClient {
  private static instance: OperationsApiClient;
  private axiosInstance: typeof axiosInstance;

  private constructor() {
    this.axiosInstance = axiosInstance;
  }

  static getInstance(): OperationsApiClient {
    if (!OperationsApiClient.instance) {
      OperationsApiClient.instance = new OperationsApiClient();
    }
    return OperationsApiClient.instance;
  }

  public async loadMarketDataAction(
    inquiryId: string,
    recordType?: string,
  ): Promise<TMarketDataResponse> {
    const response = await this.axiosInstance.get(
      endpoints.INQUIRIES.SPECIALIZED.MARKET_RESEARCH.compose({
        params: { inquiryId },
        query: { record_type: recordType },
      }),
    );
    return response.data;
  }

  public async loadPlanningEvaluationProfitabilityAction(
    inquiryId: string,
    recordType?: string,
  ): Promise<IProfitabilityResponse> {
    const response = await this.axiosInstance.get(
      endpoints.INQUIRIES.SPECIALIZED.PLANNING_EVALUATION_PROFITABILITY.compose({
        params: { inquiryId },
        query: { record_type: recordType },
      }),
    );
    return response.data;
  }

  public async loadReeMarketDataAction(
    inquiryId: string,
    recordType?: string,
  ): Promise<IReeMarketDataResponse> {
    const response = await this.axiosInstance.get(
      endpoints.INQUIRIES.SPECIALIZED.PLANNING_EVALUATION_PROFITABILITY.compose({
        params: { inquiryId },
        query: { record_type: recordType },
      }),
    );
    return response.data;
  }

  public async loadProcessLogAction(inquiryId: string): Promise<ProcessLog[]> {
    const response = await this.axiosInstance.get(
      endpoints.INQUIRIES.PROCESS_LOG.compose({
        params: { id: inquiryId },
      }),
    );
    return response.data;
  }

  public async loadInternalCommentsAction(inquiryId: string): Promise<IInternalCommentsResponse> {
    const response = await this.axiosInstance.get(
      endpoints.INQUIRIES.COMMENTS.compose({
        params: { id: inquiryId },
      }),
    );
    return response.data;
  }

  public async loadInquiryRevisionsAction(inquiryId: string): Promise<InquiryRevision[]> {
    const response = await this.axiosInstance.get(
      endpoints.INQUIRIES.CHANGELOG.compose({
        params: { id: inquiryId },
      }),
    );
    return response.data;
  }

  public async loadInquiryRevisionAction(
    revisionId: string,
  ): Promise<InquiryRevisionListElement[]> {
    const response = await this.axiosInstance.get(
      endpoints.CHANGELOG.compose({
        params: { id: revisionId },
      }),
    );
    return response.data;
  }

  public async loadInquiriesListAction(options: IInquiryListOptions): Promise<IInquiriesList> {
    const query = tableOptionsToQueryParams(options) as URLQuery;
    const response = await this.axiosInstance.get(
      endpoints.INQUIRIES.LIST.compose({ query }, { qs: { arrayFormat: 'brackets' } }),
    );
    return mapInquiriesResponse(response) as IInquiriesList;
  }

  public async loadInquiriesStatusesAction() {
    const res = await this.axiosInstance.get(endpoints.INQUIRIES.STATUSES.compose());
    return mapCountersToTabs(res.data);
  }

  public async deleteObjectImageAction(inquiryId: string, fileId: string): Promise<void> {
    await this.axiosInstance.delete(
      endpoints.INQUIRIES.SPECIALIZED.DELETE_OBJECT_IMAGES.compose({
        params: { inquiryId, fileId },
      }),
    );
  }

  public async submitMarketDataAction(
    data: Record<string, unknown>,
    inquiryId: string,
  ): Promise<any> {
    return await this.axiosInstance.patch(
      endpoints.INQUIRIES.SPECIALIZED.MARKET_RESEARCH.compose({ params: { inquiryId } }),
      {
        data: {
          attributes: data,
        },
      },
    );
  }

  public async submitPlanningEvaluationProfitabilityDataAction(
    data: Record<string, unknown>,
    inquiryId: string,
  ): Promise<any> {
    return await this.axiosInstance.patch(
      endpoints.INQUIRIES.SPECIALIZED.PLANNING_EVALUATION_PROFITABILITY.compose({
        params: { inquiryId },
      }),
      {
        data: {
          attributes: data,
        },
      },
    );
  }

  public async downloadPropertyProfilePdfAction(
    financingRole: string,
    usageType: string,
    inquiryId: string,
  ): Promise<any> {
    return await this.axiosInstance.post(
      endpoints.INQUIRIES.SPECIALIZED.DOWNLOAD_PROPERTY_PROFILE_PDF.compose({
        params: { inquiryId },
      }),
      {
        responseType: 'blob',
        data: {
          attributes: {
            role: financingRole,
            variant: usageType,
          },
        },
      },
      {
        responseType: 'blob',
      },
    );
  }

  public async addInternalCommentAction(inquiryId: string, content: any): Promise<any> {
    return await this.axiosInstance.post(
      endpoints.INQUIRIES.COMMENTS.compose({
        params: { id: inquiryId },
      }),
      {
        content,
      },
    );
  }
}

export default OperationsApiClient.getInstance();
